import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import LogoutPage from "./Logout";
import { Form, Offcanvas, Dropdown } from "react-bootstrap";
import { updateProfile } from "../../../services/ProductService";
import { toast } from "react-toastify";
import { updateProfileAction } from "../../../store/actions/AuthActions";
import { connect } from "react-redux";
import { useDispatch } from "react-redux";

const Header = ({ user }) => {
  const [headerFix, setheaderFix] = useState(false);
  const [profileModalOpen, setProfileModalOpen] = useState(false);
  const [empForm, setEmpForm] = useState({ name: "", password: "" });
  const [showPopover, setShowPopover] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    window.addEventListener("scroll", () => {
      setheaderFix(window.scrollY > 50);
    });
  }, []);

  const toggleProfileModal = () => {
    setEmpForm({ name: user?.name, password: "" });
    setProfileModalOpen(!profileModalOpen);
  };

  const togglePopover = () => {
    setShowPopover(!showPopover);
  };

  const handleChangeAdd = (e) => {
    const { name, value } = e.target;
    setEmpForm({ ...empForm, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    updateProfile(empForm)
      .then((response) => {
        dispatch(updateProfileAction(response.data.data));
        toast.success(response.data.message);
      })
      .catch((error) => {
        toast.error(error.response.data.message);
      });
  };

  const getInitials = (fullName) => {
    const nameArray = fullName.split(" ");
    // Get the first letter of the first name
    const firstNameInitial = nameArray[0].charAt(0);
    // Get the first letter of the last name (if available)
    const lastNameInitial =
      nameArray.length > 1 ? nameArray[nameArray.length - 1].charAt(0) : "";
    // Concatenate the initials
    const initials = firstNameInitial + lastNameInitial;
    return initials.toUpperCase(); // Convert to uppercase if needed
  };

  return (
    <div
      className={`header ${
        headerFix ? "is-fixed" : ""
      } border-bottom border-primary`}
    >
      <div className="header-content">
        <nav className="navbar navbar-expand">
          <div className="collapse navbar-collapse justify-content-between">
            <span className="department-name"></span>
            <div className="header-left"></div>

            <ul className="navbar-nav header-right">
              <li className="nav-item ps-3">
                <Dropdown className="header-profile mt-2">
                  <Dropdown.Toggle className="nav-link i-false" as="div">
                    <div className="header-info2 d-flex align-items-center">
                      <div className="header-media user-profile-icon">
                        {getInitials(user?.name || "")}
                      </div>
                      <div className="header-info">
                        <h6 className="text-primary">{user?.name}</h6>
                        <p className="text-dark">{user?.email}</p>
                      </div>
                    </div>
                  </Dropdown.Toggle>
                  <Dropdown.Menu align="end" className="mt-1">
                    <div className="card border-0 mb-0">
                      <div className="card-header py-2">
                        <div className="products">
                          <div className="user-profile-icon">
                            {getInitials(user?.name || "")}
                          </div>
                          <div>
                            <h6>{user?.email}</h6>
                            <span>{user?.role}</span>
                          </div>
                        </div>
                      </div>
                      <div className="card-body px-0 py-2">
                        <Link
                          to={"/profile"}
                          className="dropdown-item ai-icon "
                        >
                          <i class="fa-solid fa-user me-3"></i>
                          <span className="ms-2">Profile </span>
                        </Link>
                        <LogoutPage />
                      </div>
                    </div>
                  </Dropdown.Menu>
                </Dropdown>
              </li>
            </ul>
          </div>
        </nav>
      </div>
      <Offcanvas
        show={profileModalOpen}
        onHide={() => toggleProfileModal()}
        className="offcanvas-end"
        placement="end"
      >
        <div className="offcanvas-header">
          <h5 className="modal-title" id="#gridSystemModal">
            Profile
          </h5>
          <button
            type="button"
            className="btn-close"
            onClick={() => toggleProfileModal()}
          ></button>
        </div>
        <div className="offcanvas-body">
          <div className="container">
            <div className="card card-body mb-3">
              <h5>Profile Details</h5>
              <ul>
                <li className="my-2">
                  <strong>Name</strong>: {user?.name}
                </li>
                <li className="my-2">
                  <strong>Email</strong>: {user?.email}
                </li>
                <li className="my-2">
                  <strong>Role</strong>: {user?.role}
                </li>
                <li className="my-2">
                  <strong>Status</strong>: {user?.status}
                </li>
                {user?.role !== "Admin" ? (
                  <>
                    <li className="my-2">
                      <strong>Phone</strong>: {user?.mobile_number}
                    </li>
                    <li className="my-2">
                      <strong>Employee ID</strong>: {user?.employee_id}
                    </li>
                    <li className="my-2">
                      <strong>Designation</strong>: {user?.designation?.name}
                    </li>
                  </>
                ) : null}
              </ul>
            </div>

            <Form onSubmit={(e) => handleSubmit(e)} className="card card-body">
              <div className="row">
                <h5>Update Profile</h5>
                <div className="col-xl-12">
                  <div className="mb-3">
                    <label htmlFor="name" className="form-label">
                      Name <span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      autoFocus
                      className="form-control"
                      id="name"
                      placeholder="Enter Name"
                      value={empForm.name}
                      name="name"
                      onChange={handleChangeAdd}
                      required
                    />
                  </div>

                  <div className="mb-3">
                    <label htmlFor="password" className="form-label">
                      New Password <span>(Optional)</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="password"
                      placeholder="Enter New Password"
                      value={empForm.password}
                      name="password"
                      onChange={handleChangeAdd}
                    />
                  </div>
                </div>
              </div>
              <div>
                <button type="submit" className="btn btn-sm btn-primary me-1">
                  Submit
                </button>
                <button
                  type="button"
                  onClick={() => toggleProfileModal()}
                  className="btn btn-sm btn-danger light ms-1"
                >
                  Close
                </button>
              </div>
            </Form>
          </div>
        </div>
      </Offcanvas>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
    sideMenu: state.sideMenu,
    role: state.auth.user?.role || "Employee",
    isMobileScreen: state.auth.isMobileScreen,
  };
};

export default connect(mapStateToProps)(Header);
