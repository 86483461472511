import React, { useState, useEffect } from "react";
import Pagination from "../../constant/Pagination";
import { toast } from "react-toastify";
import { Offcanvas, Form, Modal } from "react-bootstrap";
import { connect } from "react-redux";
import { CancelRequest } from "../../../services/AxiosInstance";
import {
  getEmployeeList,
  createEmployee,
  updateEmployee,
  deleteEmployee,
} from "../../../services/OtherService";
import TableLoaderEffect from "../../constant/TableLoader";

const EmployeePage = ({ isMobileScreen, role }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [searchQuery, setSearchQuery] = useState("");
  const [isModalOpen, setIsModalOpen] = useState({ open: false, type: "Add" });
  const [selectedEmp, setSelectedEmp] = useState(null);
  const [empForm, setEmpForm] = useState({
    name: "",
    personnel_number: "",
    role: "Employee",
    password: "",
  });
  const [data, setData] = useState([]);
  const [total, setTotal] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const recordsPage = 10;

  useEffect(() => {
    if (searchQuery.length > 0) {
      CancelRequest();
    } else {
      setIsLoading(true);
    }
    getEmployeeList(currentPage, recordsPage, searchQuery)
      .then((resp) => {
        setData(resp.data.data);
        setTotal(resp.data.total);
        setIsLoading(false);
      })
      .catch((error) => {
        if (error.response) {
          console.log(error.response.data.message);
          setIsLoading(false);
        } else {
          console.log(error);
        }
      });
  }, [currentPage, searchQuery]);

  const toggleModal = (type = "Add", emp = null) => {
    let open = isModalOpen.open;
    if (type === "Add") {
      open = !open;
    } else if (type === "Edit" && emp) {
      setSelectedEmp(emp);
      setEmpForm({
        emp_id: emp.id,
        name: emp.name,
        personnel_number: emp.personnel_number,
        role: emp.role,
        password: "",
        status: emp.status,
      });
      open = true;
    } else if (type === "Delete" && emp) {
      setSelectedEmp(emp);
      open = true;
    }

    if (isModalOpen.open && emp === null) {
      setEmpForm({
        name: "",
        personnel_number: "",
        role: "Employee",
        password: "",
      });
      setSelectedEmp(null);
    }
    setIsModalOpen({ type, open });
  };

  const handleChangeAdd = (e) => {
    const { name, value } = e.target;
    setEmpForm({ ...empForm, [name]: value });
  };

  const handlePagination = (page) => {
    setIsLoading(true);
    const new_page = page.selected + 1;
    setCurrentPage(new_page);
  };

  const handleSearchChange = (e) => {
    const { value } = e.target;
    setSearchQuery(value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (isModalOpen.type === "Add") {
      try {
        const resp = await createEmployee(empForm);
        const resp_data = resp.data.data;
        setData([resp_data, ...data]);
        setTotal(total + 1);
        toast.success(resp.data.message);
        toggleModal();
      } catch (error) {
        toast.error(error.response.data.message);
      }
    } else {
      try {
        const response = await updateEmployee(empForm);
        const resp_data = response.data;
        setData(
          data.map((user) =>
            user.id === resp_data.data.id ? { ...resp_data.data } : user
          )
        );
        toast.success(resp_data.message);
        toggleModal();
      } catch (error) {
        console.log(error);
        if (error && error.response) {
          toast.error(error.response.data.message);
        }
      }
    }
  };

  const handleDelete = async () => {
    try {
      const response = await deleteEmployee(selectedEmp.emp_id);
      const resp_data = response.data;
      setData(data.filter((user) => user.id !== selectedEmp.emp_id));
      toast.success(resp_data.message);
      toggleModal();
    } catch (error) {
      console.log(error);
      if (error && error.response) {
        toast.error(error.response.data.message);
      }
    }
  };

  return (
    <>
      <div className="container-fluid">
        {isMobileScreen ? (
          <div>
            <div class="p-1 bg-white rounded rounded-pill shadow-sm mb-2">
              <div class="input-group m-1">
                <input
                  type="text"
                  class="form-control"
                  placeholder="Search..."
                  value={searchQuery}
                  onChange={handleSearchChange}
                />
                {role !== "Employee" && (
                  <button
                    className="btn btn-primary btn-sm mx-1"
                    data-bs-toggle="offcanvas"
                    onClick={() => toggleModal()}
                  >
                    Add Employee
                  </button>
                )}
              </div>
            </div>
            <div className="row">
              {data.map((item, index) => (
                <div className="col-sm-6" key={index}>
                  <div
                    class="card"
                    onClick={() =>
                      role !== "Employee" && toggleModal("Edit", item)
                    }
                  >
                    <div class="card-body">
                      <p class="card-text">
                        <strong>Name: </strong>
                        <span className="text-primary cursor-pointer fw-bolder">
                          {item.name}
                        </span>
                      </p>
                      <p class="card-text">
                        <strong>Personnel Number: </strong>
                        <span>{item.personnel_number}</span>
                      </p>
                      <p class="card-text">
                        <strong>Role: </strong>
                        <span>{item.role}</span>
                      </p>
                      <div className="my-2 d-flex justify-content-end">
                        {item.status === "Active" ? (
                          <span
                            className={`badge light border-0 badge-success`}
                          >
                            Active
                          </span>
                        ) : (
                          <span className={`badge light border-0 badge-danger`}>
                            Inactive
                          </span>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        ) : (
          <div className="row">
            <div className="col-xl-12">
              <div className="card">
                <div className="card-body p-0">
                  <div className="table-responsive active-projects style-1 ItemsCheckboxSec shorting">
                    <div className="tbl-caption d-flex justify-content-between text-wrap align-items-center">
                      <h4 className="heading mb-0 fw-bold">Employee</h4>
                      <div className="d-flex">
                        <div className="form-group me-5">
                          <input
                            type="search"
                            name="serach"
                            className="form-control "
                            placeholder="Search"
                            value={searchQuery}
                            onChange={handleSearchChange}
                          />
                        </div>
                        {role !== "Employee" && (
                          <button
                            className="btn btn-primary btn-sm ms-1"
                            data-bs-toggle="offcanvas"
                            onClick={() => toggleModal()}
                          >
                            Add Employee
                          </button>
                        )}
                      </div>
                    </div>
                    <div
                      id="employee-tbl_wrapper"
                      className="dataTables_wrapper no-footer"
                    >
                      <table
                        id="empoloyees-tblwrapper"
                        className="table ItemsCheckboxSec dataTable no-footer mb-0"
                      >
                        <thead>
                          <tr>
                            <th>Name</th>
                            <th>Personnel Number</th>
                            <th>Role</th>
                            <th>Status</th>
                          </tr>
                        </thead>
                        <tbody>
                          {isLoading ? (
                            <TableLoaderEffect colLength={6} />
                          ) : (
                            data.map((item, index) => (
                              <tr key={index}>
                                <td>
                                  {role !== "Employee" ? (
                                    <span
                                      className="text-primary cursor-pointer fw-bolder"
                                      onClick={() => toggleModal("Edit", item)}
                                    >
                                      {item.name}
                                    </span>
                                  ) : (
                                    item.name
                                  )}
                                </td>
                                <td>{item.personnel_number}</td>
                                <td>
                                  <span>{item.role}</span>
                                </td>
                                <td>
                                  {item.status === "Active" ? (
                                    <span
                                      className={`badge light border-0 badge-success`}
                                    >
                                      Active
                                    </span>
                                  ) : (
                                    <span
                                      className={`badge light border-0 badge-danger`}
                                    >
                                      Inactive
                                    </span>
                                  )}
                                </td>
                              </tr>
                            ))
                          )}
                        </tbody>
                      </table>

                      {isLoading ? (
                        <div style={{ height: "4rem" }}></div>
                      ) : (
                        <Pagination
                          currentPage={currentPage}
                          recordsPage={recordsPage}
                          dataLength={total}
                          handlePagination={handlePagination}
                        />
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>

      {/* add employee */}
      <Offcanvas
        show={isModalOpen.open && isModalOpen.type === "Add"}
        onHide={() => toggleModal()}
        onExited={() => console.log("exied")}
        className="offcanvas-end"
        placement="end"
      >
        <div className="offcanvas-header border-bottom">
          <h5 className="modal-title" id="#gridSystemModal">
            Add Employee
          </h5>
          <button
            type="button"
            className="btn-close"
            onClick={() => toggleModal()}
          ></button>
        </div>
        <div className="offcanvas-body">
          <div className="container">
            <Form onSubmit={(e) => handleSubmit(e)}>
              <div className="row">
                <div className="col-xl-12">
                  <div className="mb-3">
                    <label htmlFor="name" className="form-label">
                      Name <span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      autoFocus
                      className="form-control"
                      id="name"
                      placeholder="Enter Name"
                      value={empForm.name}
                      name="name"
                      onChange={handleChangeAdd}
                      required
                    />
                  </div>
                  <div className="mb-3">
                    <label htmlFor="personnel_number" className="form-label">
                    Personnel Number <span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="personnel_number"
                      placeholder="Enter Personnel Number"
                      value={empForm.personnel_number}
                      name="personnel_number"
                      onChange={handleChangeAdd}
                      required
                    />
                  </div>

                  <div className="mb-3">
                    <label>Role</label>
                    <br />
                    <div className="form-check custom-checkbox mb-2 form-check-inline">
                      <input
                        type="radio"
                        className="form-check-input"
                        defaultChecked
                        id="active"
                        name="role"
                        value={"Employee"}
                        onChange={handleChangeAdd}
                      />
                      <label className="form-check-label" htmlFor="active">
                        Employee
                      </label>
                    </div>
                    <div className="form-check custom-checkbox mb-2 form-check-inline">
                      <input
                        type="radio"
                        className="form-check-input"
                        id="admin"
                        name="role"
                        value={"Admin"}
                        onChange={handleChangeAdd}
                      />
                      <label className="form-check-label" htmlFor="admin">
                        Admin
                      </label>
                    </div>
                  </div>
                  
                  <div className="mb-3">
                    <label htmlFor="password" className="form-label">
                      Password <span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="password"
                      placeholder="Enter Password"
                      value={empForm.password}
                      name="password"
                      onChange={handleChangeAdd}
                      required
                    />
                  </div>
                </div>
              </div>
              <div>
                <button type="submit" className="btn btn-primary me-1">
                  Create
                </button>
                <button
                  type="button"
                  onClick={() => toggleModal()}
                  className="btn btn-danger light ms-1"
                >
                  Cancel
                </button>
              </div>
            </Form>
          </div>
        </div>
      </Offcanvas>

      {/* add user end */}

      {/* edit user */}
      <Offcanvas
        show={isModalOpen.open && isModalOpen.type === "Edit"}
        onHide={() => toggleModal()}
        className="offcanvas-end"
        placement="end"
      >
        <div className="offcanvas-header border-bottom">
          <h5 className="modal-title" id="#gridSystemModal">
            Edit Employee
          </h5>
          <button
            type="button"
            className="btn-close"
            onClick={() => toggleModal()}
          ></button>
        </div>
        <div className="offcanvas-body">
          <div className="container">
            <Form onSubmit={(e) => handleSubmit(e)}>
              <div className="row">
                <div className="col-xl-12">
                  <div className="mb-3">
                    <label htmlFor="name" className="form-label">
                      Name <span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      autoFocus
                      className="form-control"
                      id="name"
                      placeholder="Enter Name"
                      value={empForm.name}
                      name="name"
                      onChange={handleChangeAdd}
                      required
                    />
                  </div>
                  <div className="mb-3">
                    <label htmlFor="personnel_number" className="form-label">
                    Personnel Number <span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="personnel_number"
                      placeholder="Enter Personnel Number"
                      value={empForm.personnel_number}
                      name="personnel_number"
                      onChange={handleChangeAdd}
                      required
                    />
                  </div>

                  <div className="mb-3">
                    <label>Role</label>
                    <br />
                    <div className="form-check custom-checkbox mb-2 form-check-inline">
                      <input
                        type="radio"
                        className="form-check-input"
                        checked={empForm.role === "Employee"}
                        id="active"
                        name="role"
                        value={"Employee"}
                        onChange={handleChangeAdd}
                      />
                      <label className="form-check-label" htmlFor="active">
                        Employee
                      </label>
                    </div>
                    <div className="form-check custom-checkbox mb-2 form-check-inline">
                      <input
                        type="radio"
                        className="form-check-input"
                        checked={empForm.role === "Admin"}
                        id="admin"
                        name="role"
                        value={"Admin"}
                        onChange={handleChangeAdd}
                      />
                      <label className="form-check-label" htmlFor="admin">
                        Admin
                      </label>
                    </div>
                  </div>

                  <div className="mb-3">
                    <label htmlFor="password" className="form-label">
                      New Password <span>(Optional)</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="password"
                      placeholder="Enter New Password"
                      value={empForm.password}
                      name="password"
                      onChange={handleChangeAdd}
                    />
                  </div>

                  <div className="mb-3">
                    <label>Status</label>
                    <br />
                    <div className="form-check custom-checkbox mb-2 form-check-inline">
                      <input
                        type="radio"
                        className="form-check-input"
                        checked={empForm.status === "Active"}
                        id="active"
                        name="status"
                        value={"Active"}
                        onChange={handleChangeAdd}
                      />
                      <label className="form-check-label" htmlFor="active">
                        Active
                      </label>
                    </div>
                    <div className="form-check custom-checkbox mb-2 form-check-inline">
                      <input
                        type="radio"
                        className="form-check-input"
                        checked={empForm.status === "Inactive"}
                        id="inactive"
                        name="status"
                        value={"Inactive"}
                        onChange={handleChangeAdd}
                      />
                      <label className="form-check-label" htmlFor="inactive">
                        Inactive
                      </label>
                    </div>
                  </div>
                </div>
              </div>
              <div className="d-flex align-items-center justify-content-between">
                <button type="submit" className="btn btn-primary me-1">
                  Update
                </button>
                <button
                  type="button"
                  onClick={() => toggleModal("Delete", empForm)}
                  className="btn btn-danger light ms-1"
                >
                  Delete
                </button>
              </div>
            </Form>
          </div>
        </div>
      </Offcanvas>

      {/* edit user end */}

      {/* delete user */}
      <Modal
        className="modal fade modal-sm"
        id="exampleModal1"
        show={isModalOpen.open && isModalOpen.type === "Delete"}
        onHide={() => toggleModal()}
      >
        <div className="modal-content">
          <div className="modal-header">
            <h1 className="modal-title fs-5" id="exampleModalLabel1">
              Delete Employe
            </h1>
          </div>
          <div className="modal-body">
            <p>Are you sure you want to delete employee {selectedEmp?.name}</p>
            <button
              type="button"
              className="btn btn-sm btn-primary mt-3 "
              onClick={() => toggleModal()}
            >
              Cancel
            </button>
            <button
              type="button"
              className="btn btn-sm btn-danger mt-3 ms-2"
              onClick={handleDelete}
            >
              Delete User
            </button>
          </div>
        </div>
      </Modal>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    role: state.auth.user?.role || "Employee",
    isMobileScreen: state.auth.isMobileScreen,
  };
};

export default connect(mapStateToProps)(EmployeePage);
