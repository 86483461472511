import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Routes, Route, Outlet, Navigate } from "react-router-dom";

/// Css
import "./index.css";

/// Layout
import Nav from "./layouts/nav";
import Footer from "./layouts/Footer";
import ScrollToTop from "./layouts/ScrollToTop";

/// Dashboard
import Home from "./components/Dashboard/Home";
import EmployeePage from "./components/Employee";
import EngineerPage from "./components/Enginner";
import WorkLocationPage from "./components/WorkLocation";
import MaterialPage from "./components/Material";
import LogBookListPage from "./components/LogBook";
import LogBookAddPage from "./components/LogBook/UnPlannedLogBook/addLogBook";
import EditLogBook from "./components/LogBook/UnPlannedLogBook/editLogBook";
import ShowLogBook from "./components/LogBook/view";
import PlanedJobPages from "./components/PlannedJob";
import ProfilePage from "./components/Profile";
import JobActivity from "./components/OpenDashBoard";

/// Pages
import Error403 from "./pages/Error403";
import Error404 from "./pages/Error404";
import Login from "./pages/Login";

import logo from "../images/logo/intelilogshiftTextLogo.png";
import BottomNav from "./layouts/nav/BottomNav";

const allroutes = [
  // Dashboard
  { url: "dashboard", element: <Home />, is_only_admin: false },
  { url: "engineer", element: <EngineerPage /> },
  { url: "employee", element: <EmployeePage /> },
  { url: "work-location", element: <WorkLocationPage /> },
  { url: "material", element: <MaterialPage /> },
  { url: "log-book", element: <LogBookListPage /> },
  { url: "log-book-add", element: <LogBookAddPage /> },
  {
    url: "log-book-edit/:id",
    element: <EditLogBook />,
  },
  {
    url: "log-book-view/:id",
    element: <ShowLogBook />,
  },
  { url: "planned-job", element: <PlanedJobPages /> },
  { url: "planned-job/:active_tab?", element: <PlanedJobPages /> },
  { url: "profile", element: <ProfilePage /> },
];

const FlashScreen = () => {
  return (
    <div className="flash-screen">
      <div className="flash-body">
        <img src={logo} alt="logo" />
      </div>
    </div>
  );
};

const Markup = ({ isAuthenticated, isCheckingAuth, role, sideMenu }) => {
  const [isMobileScreen, setIsMobileScreen] = useState(false);
  useEffect(() => {
    function handleResize() {
      setIsMobileScreen(window.innerWidth < 768);
    }
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const NotFound = () => <Error404 />;

  function MainLayout() {
    return (
      <div
        id="main-wrapper"
        className={`show ${sideMenu ? "menu-toggle" : ""}`}
      >
        <Nav />
        <div
          className="content-body"
          style={{ minHeight: window.screen.height - 45 }}
        >
          <Outlet />
          {isMobileScreen && role === "Employee" ? <BottomNav /> : <Footer />}
        </div>
      </div>
    );
  }

  const HandleUserAuthentication = ({ data }) => {
    if (!isAuthenticated) {
      return <Navigate to="/" />;
    }
    return data.element;
  };

  return isCheckingAuth ? (
    <FlashScreen />
  ) : (
    <>
      <Routes>
        <Route
          path="/"
          exact
          element={isAuthenticated ? <Navigate to="/dashboard" /> : <Login />}
        />
        <Route element={<MainLayout />}>
          {allroutes.map((data, i) => (
            <Route
              key={i}
              exact
              path={`${data.url}`}
              element={<HandleUserAuthentication data={data} />}
            />
          ))}
        </Route>
        <Route path="/activity" element={<JobActivity />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
      <ScrollToTop />
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    role: state.auth.user?.role || "Employee",
    sideMenu: state.sideMenu,
    isAuthenticated: state.auth.user,
    isCheckingAuth: state.auth.isCheckingAuth,
  };
};

export default connect(mapStateToProps)(Markup);
