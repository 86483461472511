import Select from "react-select";
import React, { useState, useEffect } from "react";
import { getSelectOptionList } from "../../services/OtherService";
import { CancelRequest } from "../../services/AxiosInstance";

const DynamicSelectInput = ({
  name,
  id,
  setParentInputValue,
  parentClassName = "mb-3",
  endpoint_name = "area",
  defaultValue = null,
  label = null,
  labelHint=true,
  is_multi = false,
  isRequired = false,
  is_invalid = false,
  placeholder = "Select ...",
  isClearable = false,
}) => {
  const [selectOption, setSelectOption] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const [selectValue, setSelectValue] = useState(
    defaultValue ? defaultValue : is_multi ? [] : null
  );

  useEffect(() => {
    if (inputValue.length > 0) {
      CancelRequest();
    }
    setIsLoading(true);
    getSelectOptionList(endpoint_name, 1, 20, inputValue)
      .then((resp) => {
        if (resp) {
          setSelectOption(resp.data.data);
          setIsLoading(false);
        }
      })
      .catch((error) => {
        if (error.response) {
          console.log(error.response.data.message);
          setIsLoading(false);
        } else {
          console.log(error);
        }
      });
  }, [inputValue, endpoint_name]);

  const handleSelectInputChange = async (value, reason) => {
    if (reason.action === "input-change") {
      setInputValue(value);
    }
  };
  const handleSelectValueChange = (data) => {
    setSelectValue(data);
    if (data) {
      setParentInputValue(
        name,
        is_multi ? data.map((item) => item.value) : data.value
      ); // Notify parent component of select value change
    } else {
      setParentInputValue(name, is_multi ? [] : ""); // Notify parent component of select value change
    } // Notify parent component of select value change
  };

  return (
    <div className={parentClassName}>
      {label && (
        <label htmlFor={id} className="form-label">
          {label}{" "}
          {labelHint && (
            isRequired ? (
              <span className="text-danger">*</span>
            ) : (
              <span className="text-light">(Optional)</span>
            )
          )}
        </label>
      )}
      <Select
        value={selectValue}
        onChange={(data) => handleSelectValueChange(data)}
        placeholder={placeholder}
        isLoading={isLoading}
        inputValue={inputValue}
        id={id}
        name={name}
        isClearable={isClearable}
        isMulti={is_multi}
        closeMenuOnSelect={is_multi ? false : true}
        options={selectOption}
        className={`react-select ${
          is_invalid && isRequired ? "is-invalid" : ""
        }`}
        classNamePrefix="select"
        onInputChange={(value, reason) =>
          handleSelectInputChange(value, reason)
        }
      />
    </div>
  );
};

export const StaticSelectInput = ({
  name,
  id,
  setParentInputValue,
  optionsList = [],
  defaultValue = null,
  parentClassName = "mb-3",
  label = null,
  labelHint=true,
  isRequired = false,
  is_invalid = false,
  is_multi = false,
  placeholder = "Select ...",
  isClearable = false,
}) => {
  const [selectValue, setSelectValue] = useState(
    defaultValue ? defaultValue : is_multi ? [] : null
  );
  const handleSelectValueChange = (data) => {
    setSelectValue(data);
    if (data) {
      setParentInputValue(
        name,
        is_multi ? data.map((item) => item.value) : data.value
      ); // Notify parent component of select value change
    } else {
      setParentInputValue(name, is_multi ? [] : ""); // Notify parent component of select value change
    }
  };

  return (
    <div className={parentClassName}>
      {label && (
        <label htmlFor={id} className="form-label">
          {label}{" "}
          {labelHint && (
            isRequired ? (
              <span className="text-danger">*</span>
            ) : (
              <span className="text-light">(Optional)</span>
            )
          )}
        </label>
      )}
      <Select
        value={selectValue}
        onChange={(data) => handleSelectValueChange(data)}
        placeholder={placeholder}
        isClearable={isClearable}
        id={id}
        name={name}
        isMulti={is_multi}
        closeMenuOnSelect={is_multi ? false : true}
        options={optionsList}
        className={`react-select ${
          is_invalid && isRequired ? "is-invalid" : ""
        }`}
        classNamePrefix="select"
      />
    </div>
  );
};

export default DynamicSelectInput;
