import { SVGICON } from "../../constant/theme";

export const MenuList = [
  {
    title: "Dashboard",
    iconStyle: SVGICON.MenuDashboard,
    to: "/dashboard",
    
  },
  {
    title: "Log Book",
    iconStyle: SVGICON.MenuLogBook,
    to: "/log-book",
    
  },{
    title: "Planned Job",
    iconStyle: SVGICON.MenuPlannedJob,
    to: "/planned-job",
    
  },
  {
    title: "Work Location",
    iconStyle: SVGICON.MenuLocation,
    to: "/work-location",
    
  },
  {
    title: "Material",
    iconStyle: SVGICON.MenuMaterial,
    to: "/material",
    
  },
  {
    title: "Engineer",
    iconStyle: SVGICON.MenuEngineer,
    to: "/engineer",
    
  },

  {
    title: "Employee",
    iconStyle: SVGICON.MenuEmployee,
    to: "/employee"
  },
];
