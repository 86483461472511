import React, { useState } from "react";
import { Offcanvas } from "react-bootstrap";
import DynamicSelectInput, {
  StaticSelectInput,
} from "../../../constant/SelectInput";

function PlannedLogFilter({ onApplyChanges }) {
  const initialState = {
    work_location: "",
    shift_type: "",
    planned_job: "",
    status: "",
    start_date: "",
    end_date: "",
  };
  const [filterInput, setFilterInput] = useState(initialState);
  const [showModal, setShowModal] = useState(false);
  const [filterCount, setFilterCount] = useState(0);

  const toggleModal = () => setShowModal(!showModal);

  const handleSelectChange = (name, value) => {
    setFilterInput({ ...filterInput, [name]: value });
  };

  const handleApply = () => {
    onApplyChanges(filterInput);
    toggleModal();
    setFilterCount(Object.values(filterInput).filter((item) => item).length);
  };

  const handleClear = () => {
    setFilterInput(initialState);
    onApplyChanges(initialState);
    setFilterCount(0);
    toggleModal();
  };

  return (
    <>
      <span className="c-pointer" onClick={toggleModal}>
        <i class="fa-solid fa-filter fa-xl text-primary"></i>
        {filterCount > 0 && <span className="filter-count">{filterCount}</span>}
      </span>

      <Offcanvas
        className="offcanvas-end"
        placement="end"
        id="exampleModal1"
        show={showModal}
        onHide={toggleModal}
      >
        <div className="modal-content">
          <div className="modal-header">
            <h1 className="modal-title fs-6" id="exampleModalLabel1">
              Advance Filter
            </h1>
            <button
              type="button"
              className="btn-close"
              onClick={toggleModal}
            ></button>
          </div>
          <div className="modal-body">
            <div>
              <div className="row">
                <div className="col-xl-12">
                  <DynamicSelectInput
                    setParentInputValue={handleSelectChange}
                    endpoint_name="search/work_location"
                    name={"work_location"}
                    isClearable={true}
                    labelHint={false}
                    defaultValue={
                      filterInput.work_location
                        ? {
                            label: filterInput.work_location,
                            value: filterInput.work_location,
                          }
                        : null
                    }
                    id={"work_location"}
                    label={"Work Location"}
                    placeholder={"Select Work Location"}
                  />
                  <StaticSelectInput
                    optionsList={[
                      {
                        label: "General Shift",
                        value: "General Shift",
                      },
                      { label: "A Shift", value: "A Shift" },
                      { label: "B Shift", value: "B Shift" },
                      { label: "C Shift", value: "C Shift" },
                    ]}
                    setParentInputValue={handleSelectChange}
                    name={"shift_type"}
                    isClearable={true}
                    labelHint={false}
                    defaultValue={
                      filterInput.shift_type
                        ? {
                            label: filterInput.shift_type,
                            value: filterInput.shift_type,
                          }
                        : null
                    }
                    id={"shift_type"}
                    label={"Shift Type"}
                    placeholder={"Select Shift Type"}
                  />
                  <DynamicSelectInput
                    setParentInputValue={handleSelectChange}
                    endpoint_name="search/plannedjob"
                    name={"planned_job"}
                    isClearable={true}
                    labelHint={false}
                    defaultValue={
                      filterInput.planned_job
                        ? {
                            label: filterInput.planned_job,
                            value: filterInput.planned_job,
                          }
                        : null
                    }
                    id={"planned_job"}
                    label={"Planned Job"}
                    placeholder={"Select Planned Job"}
                  />

                  <StaticSelectInput
                    optionsList={[
                      { label: "Pending", value: "Pending" },
                      {
                        label: "Completed",
                        value: "Completed",
                      },
                      { label: "Defected", value: "Defected" },
                      { label: "Overdue", value: "Overdue" },
                      { label: "Upcoming", value: "Upcoming" },
                    ]}
                    setParentInputValue={handleSelectChange}
                    isClearable={true}
                    labelHint={false}
                    name={"status"}
                    id={"status"}
                    label={"Status"}
                    defaultValue={
                      filterInput.status
                        ? {
                            label: filterInput.status,
                            value: filterInput.status,
                          }
                        : null
                    }
                    placeholder={"Select Status"}
                  />
                  <div className="mb-3">
                    <label htmlFor="occurred_at" className="form-label">
                      Start Period
                    </label>
                    <input
                      className="form-control"
                      type="date"
                      id="start_date"
                      value={filterInput.start_date}
                      name="start_date"
                      onChange={(e) =>
                        handleSelectChange(e.target.name, e.target.value)
                      }
                    />
                  </div>

                  <div className="mb-3">
                    <label htmlFor="occurred_at" className="form-label">
                      End Period
                    </label>
                    <input
                      className="form-control"
                      type="date"
                      id="end_date"
                      value={filterInput.end_date}
                      name="end_date"
                      onChange={(e) =>
                        handleSelectChange(e.target.name, e.target.value)
                      }
                    />
                  </div>
                </div>
              </div>
              <div className="mt-4">
                <button
                  type="button"
                  className="btn btn-primary me-1"
                  onClick={handleApply}
                >
                  Apply
                </button>
                <button
                  type="button"
                  onClick={handleClear}
                  className="btn btn-danger light ms-1"
                >
                  Clear
                </button>
              </div>
            </div>
          </div>
        </div>
      </Offcanvas>
    </>
  );
}

export default PlannedLogFilter;
