import React, { useState, useEffect } from 'react';
//import {useNavigation} from 'react-router-dom'
import { dashboard } from '../../../../services/DashboardService';

const InspectLogCountWidgetCard = () => {
    const [data, setData] = useState(null)
    const [isLoading, setIsLoading] = useState(false)
    //const navigation = useNavigation()

    useEffect(() => {
        setIsLoading(true)
        dashboard().then((response) => {
            setData(response.data.data);
        })
            .catch((error) => {
                console.log(error.response.data.message);
            });
        setIsLoading(false)
    }, [])

    return (
        <>
            {/* <div className="col-xl-3 col-sm-6">
                <div className="card same-card">
                    <div className="card-body d-flex align-items-center  py-2">
                        {isLoading ? (
                            <div className="placeholder-glow text-primary">
                                <span className="placeholder col-4 rounded-circle" style={{ width: '5rem', height: '5rem', marginRight: '2.5rem' }}></span>
                            </div>
                        ) : ( data && <InspectionLogPieChart
                                series={[
                                    data?.inspection_pending_log || 0,
                                    data?.inspection_completed_log || 0,
                                    data?.inspection_defected_log|| 0
                                ]} />
                        )
                        }
                        <ul className="project-list">
                            <li><h6>Today Inspection Chart</h6></li>
                            <li>
                                <i className="fa-solid fa-square text-warning me-2"></i>Pending
                            </li>
                            <li>
                                <i className="fa-solid fa-square text-success me-2"></i>Completed
                            </li>
                            <li>
                                <i className="fa-solid fa-square text-info me-2"></i>Defected
                            </li>
                        </ul>
                    </div>
                </div>
            </div> */}

            <div className="col-xl-2 col-sm-6">
                <div className="card">
                    <div className="card-body depostit-card">
                        <div className="depostit-card-media d-flex justify-content-between style-1">
                            <div>
                                <h6>Pending Inspection</h6>
                                <h3>
                                    {isLoading ? (
                                        <div className="placeholder-glow text-primary">
                                            <span className="placeholder col-4 rounded"></span>
                                        </div>) : data?.inspection_pending_log || 0}
                                </h3>
                            </div>
                            <div className="icon-box bg-warning-light">
                                <i className="fa-solid fa-hourglass-end text-warning fa-xl"></i>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="col-xl-2 col-sm-6">
                <div className="card">
                    <div className="card-body depostit-card">
                        <div className="depostit-card-media d-flex justify-content-between style-1">
                            <div>
                                <h6>Completed Inspection</h6>
                                <h3>
                                    {isLoading ? (
                                        <div className="placeholder-glow text-primary">
                                            <span className="placeholder col-4 rounded"></span>
                                        </div>) : data?.inspection_completed_log || 0}
                                </h3>
                            </div>
                            <div className="icon-box bg-success-light">
                                <i className="fa-solid fa-square-check fa-xl text-success"></i>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="col-xl-2 col-sm-6 same-card">
                <div className="card">
                    <div className="card-body depostit-card">
                        <div className="depostit-card-media d-flex justify-content-between align-items-center style-1">
                            <div>
                                <h6>Defected Inspection</h6>
                                <h3>
                                    {isLoading ? (
                                        <div className="placeholder-glow text-primary">
                                            <span className="placeholder col-4 rounded"></span>
                                        </div>) : data?.inspection_defected_log || 0}
                                </h3>
                            </div>
                            <div className="icon-box bg-info-light">
                                <i className="fa-solid fa-triangle-exclamation text-info fa-xl"></i>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="col-xl-3 col-sm-6 same-card">
                <div className="card">
                    <div className="card-body depostit-card">
                        <div className="depostit-card-media d-flex justify-content-between align-items-center style-1">
                            <div>
                                <h6>Overdue Inspection</h6>
                                <h3>
                                    {isLoading ? (
                                        <div className="placeholder-glow text-danger">
                                            <span className="placeholder col-4 rounded"></span>
                                        </div>) : data?.inspection_overdue_log || 0}
                                </h3>
                            </div>
                            <div className="icon-box bg-danger-light">
                                <i className="fa-solid fa-business-time text-danger fa-xl"></i>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="col-xl-3 col-sm-6 same-card">
                <div className="card">
                    <div className="card-body depostit-card">
                        <div className="depostit-card-media d-flex justify-content-between align-items-center style-1">
                            <div>
                                <h6>Upcoming Inspection</h6>
                                <h3>
                                    {isLoading ? (
                                        <div className="placeholder-glow text-secondary">
                                            <span className="placeholder col-4 rounded"></span>
                                        </div>) : data?.inspection_upcomming_log || 0}
                                </h3>
                            </div>
                            <div className="icon-box bg-secondary-light">
                                <i className="fa-solid fa-calendar-day text-secondary fa-xl"></i>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default InspectLogCountWidgetCard;