import React, { useState, useEffect, useRef } from "react";
import Pagination from "../../constant/Pagination";
import { toast } from "react-toastify";
import { Form, Modal, Spinner } from "react-bootstrap";
import { CancelRequest } from "../../../services/AxiosInstance";
import {
  getCheckListList,
  createCheckList,
  updateCheckList,
} from "../../../services/PlannedJobService";

const CheckListTab = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [searchQuery, setSearchQuery] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isViewModalOpen, setIsViewModalOpen] = useState(false);
  const [isSearchLoading, setIsSearchLoading] = useState(false);
  const [checkListInput, setCheckListInput] = useState({ name: "", sop: null });
  const [data, setData] = useState([]);
  const [total, setTotal] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [isSubmitLoading, setIsSubmitLoading] = useState(false);
  const recordsPage = 10;
  const fileInputRef = useRef(null);

  useEffect(() => {
    if (searchQuery.length > 0) {
      CancelRequest();
    } else {
      setIsLoading(true);
    }
    getCheckListList(currentPage, recordsPage, searchQuery)
      .then((resp) => {
        setData(resp.data.data);
        setTotal(resp.data.total);
        setIsLoading(false);
        setIsSearchLoading(false);
      })
      .catch((error) => {
        if (error.response) {
          console.log(error.response.data.message);
          setIsLoading(false);
          setIsSearchLoading(false);
        } else {
          console.log(error);
        }
      });
  }, [currentPage, searchQuery]);

  const toggleViewModal = () => {
    if (isViewModalOpen) {
    }
    setIsViewModalOpen(!isViewModalOpen);
  };

  const toggleModal = (type = "Add", item = null) => {
    let open = isModalOpen.open;

    if (type === "Add") {
      open = !open;
    } else if (type === "Edit" && item) {
      setCheckListInput({
        id: item.id,
        name: item.name,
        sop: null,
        sop_file_name: item.sop ? item.sop.split('/').slice(3) : ""
      });
      open = true;
    }
    if (isModalOpen.open && item === null) {
      setCheckListInput({
        name: "",
        sop: null,
      });
      open = false;
    }
    setIsModalOpen({ type, open });
  };

  const handleSearchChange = (e) => {
    const { value } = e.target;
    setSearchQuery(value);
    setIsSearchLoading(true);
    setCurrentPage(1);
  };

  const handlePagination = (page) => {
    setIsLoading(true);
    const new_page = page.selected + 1;
    setCurrentPage(new_page);
  };

  const handleChangeAdd = (e) => {
    const { name, value } = e.target;
    setCheckListInput({ ...checkListInput, [name]: value });
  };

  const handleDrop = (e) => {
    e.preventDefault();
    const droppedFile = e.dataTransfer.files[0];
    setCheckListInput({ ...checkListInput, sop: droppedFile });
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const handleFileInputChange = (e) => {
    const selectedFile = e.target.files[0];
    setCheckListInput({ ...checkListInput, sop: selectedFile });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (isModalOpen.type === "Add") {
      try {
        setIsSubmitLoading(true);
        const reqBody = new FormData();
        reqBody.append("name", checkListInput.name);
        if (checkListInput.sop) {
          reqBody.append("sop", checkListInput.sop);
        }
        const resp = await createCheckList(reqBody);
        const resp_data = resp.data.data;
        setData([resp_data, ...data]);
        setTotal(total + 1);
        toast.success(resp.data.message);
        toggleModal("Add");
      } catch (error) {
        toast.error(error.response.data.message);
      } finally {
        setIsSubmitLoading(false);
      }
    } else {
      try {
        setIsSubmitLoading(true);
        const reqBody = new FormData();
        reqBody.append("id", checkListInput.id);
        reqBody.append("name", checkListInput.name);
        if (checkListInput.sop) {
          reqBody.append("sop", checkListInput.sop);
        }
        const response = await updateCheckList(reqBody);
        const responseData = response.data.data;
        setData(
          data.map((item) =>
            item.id === responseData.id ? { ...responseData } : item
          )
        );
        toast.success(response.data.message);
        toggleModal("Edit");
      } catch (error) {
        console.log(error);
        if (error && error.response) {
          toast.error(error.response.data.message);
        }
      } finally {
        setIsSubmitLoading(false);
      }
    }
  };

  return (
    <>
      <div className="card">
        <div className="card-body p-0">
          <div className="table-responsive active-projects style-1 ItemsCheckboxSec shorting">
            <div className="tbl-caption d-flex justify-content-between text-wrap align-items-center">
              <h4 className="heading mb-0">CheckList</h4>
              <div className="d-flex align-items-start align-items-sm-center flex-column flex-sm-row">
                <div className="me-3 mb-3 mb-md-0">
                  <div className="input-group tp-input-group">
                    <input
                      type="text"
                      className="form-control border-end-0"
                      placeholder="Search"
                      value={searchQuery}
                      aria-describedby="basic-search"
                      onChange={handleSearchChange}
                    />
                    <div
                      className="input-group-text bg-white border-primary"
                      id="basic-search"
                    >
                      {isSearchLoading && (
                        <Spinner variant="primary" size="sm" />
                      )}
                      {!isSearchLoading && searchQuery.length > 0 && (
                        <i
                          className="fa-solid fa-xmark c-pointer text-primary"
                          onClick={() => {
                            setCurrentPage(1);
                            setSearchQuery("");
                          }}
                        ></i>
                      )}
                    </div>
                  </div>
                </div>
                <button
                  className="btn btn-primary"
                  onClick={() => toggleModal()}
                >
                  Add CheckList
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="row">
        {isLoading ? (
          <h3>Loading</h3>
        ) : (
          data.map((item) => {
            return (
              <div className="col-lg-3 col-md-4 col-sm-6" key={item.id}>
                <div className="card">
                  <div className="card-body d-flex justify-content-between align-items-center ">
                    <div>
                      <h5 className="card-title ">{item.name}</h5>
                      {item.sop && <span className="c-pointer" onClick={()=> window.open(`${process.env.REACT_APP_BASE_URL}${item.sop}`)}>Download SOP</span>}
                    </div>
                    <button
                      type="button"
                      className="btn btn-primary tp-btn-light btn-icon-xs"
                      onClick={() => toggleModal("Edit", item)}
                    >
                      <i className="fa-solid fa-pen-to-square"></i>
                    </button>
                  </div>
                </div>
              </div>
            );
          })
        )}
      </div>

      {isLoading ? (
        <div style={{ height: "4rem" }}></div>
      ) : (
        <Pagination
          currentPage={currentPage}
          recordsPage={recordsPage}
          dataLength={total}
          handlePagination={handlePagination}
        />
      )}

      <Modal
        show={isModalOpen.open && isModalOpen.type === "Add"}
        onHide={() => toggleModal()}
        className="modal fade modal-md"
        placement="end"
      >
        <div className="modal-conten">
          <div className="modal-header border-bottom">
            <h5 className="modal-title" id="#gridSystemModal">
              Add CheckList
            </h5>
            <button
              type="button"
              className="btn-close"
              onClick={toggleModal}
            ></button>
          </div>

          <div className="modal-body">
            <Form onSubmit={(e) => handleSubmit(e)}>
              <div className="row">
                <div className="col-xl-12">
                  <div className="mb-3">
                    <label className="form-check-label">
                      Name <span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="name"
                      name="name"
                      value={checkListInput.name}
                      placeholder="Enter checklist Name"
                      onChange={handleChangeAdd}
                    />
                  </div>
                  <div className="mb-3">
                    <label className="form-check-label">
                      SOP <span className="text-light">(Optional)</span>
                    </label>
                    <div
                      onDrop={handleDrop}
                      onDragOver={handleDragOver}
                      style={{
                        border: "2px dashed #aaa",
                        borderRadius: "5px",
                        padding: "20px",
                        textAlign: "center",
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        fileInputRef.current.click();
                      }}
                    >
                      <input
                        type="file"
                        ref={fileInputRef}
                        onChange={handleFileInputChange}
                        style={{ display: "none" }}
                      />
                      <p>
                        Drag and drop a file here, or click to select a file
                      </p>
                      {checkListInput.sop && (
                        <p>Selected file: {checkListInput.sop.name}</p>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="mt-3">
                <button
                  type="submit"
                  className="btn btn-block  btn-primary"
                  disabled={isSubmitLoading}
                >
                  {isSubmitLoading ? (
                    <span>
                      <Spinner variant="light" size="sm" className="me-2" />
                      Creating...
                    </span>
                  ) : (
                    "Create"
                  )}
                </button>
              </div>
            </Form>
          </div>
        </div>
      </Modal>
      <Modal
        show={isModalOpen.open && isModalOpen.type === "Edit"}
        onHide={() => toggleModal()}
        className="modal fade modal-md"
        placement="end"
      >
        <div className="modal-conten">
          <div className="modal-header border-bottom">
            <h5 className="modal-title" id="#gridSystemModal">
              Edit CheckList
            </h5>
            <button
              type="button"
              className="btn-close"
              onClick={() => toggleModal()}
            ></button>
          </div>

          <div className="modal-body">
            {checkListInput && (
              <Form onSubmit={(e) => handleSubmit(e)}>
                <div className="row">
                  <div className="col-xl-12">
                    <div className="mb-3">
                      <label className="form-check-label">
                        Name <span className="text-danger">*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="name"
                        name="name"
                        value={checkListInput.name}
                        placeholder="Enter checklist Name"
                        onChange={handleChangeAdd}
                      />
                    </div>
                    <div className="mb-3">
                      <label className="form-check-label">
                        SOP <span className="text-light">(Optional)</span>
                      </label>
                      <div
                        onDrop={handleDrop}
                        onDragOver={handleDragOver}
                        style={{
                          border: "2px dashed #aaa",
                          borderRadius: "5px",
                          padding: "20px",
                          textAlign: "center",
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          fileInputRef.current.click();
                        }}
                      >
                        <input
                          type="file"
                          ref={fileInputRef}
                          onChange={handleFileInputChange}
                          style={{ display: "none" }}
                        />
                        <p>
                          Drag and drop a file here, or click to select a file
                        </p>
                        {checkListInput.sop && (
                          <p>Selected file: {checkListInput.sop.name}</p>
                        )}
                      </div>
                      {checkListInput.sop_file_name && <div className="text-warning mt-4">Existing SOP file: <strong>{checkListInput.sop_file_name}</strong></div>}
                    </div>
                  </div>
                </div>
                <div className="my-2">
                  <button
                    type="submit"
                    className="btn btn-block btn-primary"
                    disabled={isSubmitLoading}
                  >
                    {isSubmitLoading ? (
                      <span>
                        <Spinner variant="light" size="sm" className="me-2" />
                        Updating...
                      </span>
                    ) : (
                      "Update"
                    )}
                  </button>
                </div>
              </Form>
            )}
          </div>
        </div>
      </Modal>

      <Modal show={isViewModalOpen} onHide={toggleViewModal}>
        <div className="modal-header">
          <h4 className="modal-title my-1" id="#gridSystemModal">
            CheckList SOP
          </h4>
          <button
            type="button"
            className="btn-close mx-3"
            onClick={toggleViewModal}
          ></button>
        </div>
        <div className="modal-body"></div>
      </Modal>
    </>
  );
};

export default CheckListTab;
