import React, { useState, useEffect, useRef } from "react";
import logo from "../../../images/logo/intelilogshiftLogo.png";
import logoText from "../../../images/logo/intelilogshiftText.png";
import InteliLogo from "../../../images/logo/logo_text.png";
import InteliIcon from "../../../images/logo/logo.png";
import { activityDashboard } from "../../../services/DashboardService";

const JobActivity = () => {
  const [logList, setLogList] = useState([]);
  const [isLoading, setIsLoading] = useState([])
  const logRef = useRef(null);

  const fetchData = () => {
    if (logList.length === 0) {
      setIsLoading(true)
    }
    activityDashboard()
      .then((resp) => {
        const resp_data = resp.data.data;
        setLogList(resp_data);
        if (logList.length === 0) {
          setIsLoading(false)
        }
      })
      .catch((error) => {
        if (error.response) {
          console.log(error.response.data.message);
          if (logList.length === 0) {
            setIsLoading(false)
          }
        } else {
          console.log(error);
        }
      });
  };

  useEffect(() => {
    // Fetch data initially
    fetchData();
  }, []); // Empty dependency array to only run effect once on mount

  useEffect(() => {
    // Auto scroll the columns
    const logPullInterval = setInterval(() => {
      if (logRef.current) {
        const { scrollTop, scrollHeight, clientHeight } = logRef.current;
        // Check if reached bottom
        if ((scrollHeight - scrollTop) <= clientHeight + 0.5) {
          logRef.current.scrollTop = 0; // Reset scroll to top
          fetchData()
        } else {
          logRef.current.scrollBy({
            top: 1,
            behavior: "smooth",
          });
        }
      }
    }, 100); // Adjust the speed by changing the interval time
    return () => {
      clearInterval(logPullInterval);
    };
  }, []); // Empty dependency array to run effect only once on mount

  const makeFullScreen = () => {
    const el = document.querySelector(".main-content")
    if (!document.fullscreenElement) {
      el.requestFullscreen();
      return;
    }
    document.exitFullscreen();
  }

  const StatusButtonComponent = ({ status }) => {
    let statusbtn;

    switch (status) {
      case "Pending":
        statusbtn = (
          <span className={`status-btn bg-warning`}>Pending</span>
        );
        break;
      case "Initiated":
        statusbtn = (
          <span className={`status-btn bg-warning`}>Initiated</span>
        );
        break;
      case "Ongoing":
        statusbtn = (
          <span className={`status-btn bg-info`}>Ongoing</span>
        );
        break;
      case "Completed":
        statusbtn = (
          <span className={`status-btn bg-success`}>Completed</span>
        );
        break;
      case "Defected":
        statusbtn = (
          <span className={`status-btn bg-info`}>Defected</span>
        );
        break;
      case "Overdue":
        statusbtn = (
          <span className={`status-btn bg-danger`}>Overdue</span>
        );
        break;
      default:
        statusbtn = (
          <span className={`badge rounded-pill bg-secondary btn-sm`} disabled>
            Upcoming
          </span>
        );
    }
    return statusbtn;
  };

  return (
    <div className="main-content" style={{ background: "#e5f1fd", height: "100vh" }}>
      <div className={`bg-primary-light d-flex align-items-center justify-content-between py-2 px-4 mb-4`}>
        <div className="company-logo">
          <img src={InteliIcon} alt="logo" width={80} />
          <img className="ms-2" src={InteliLogo} alt="logo" width={100} />
        </div>
        <div>
          <h1 className="mb-0 text-primary" onClick={makeFullScreen}>Job Activity</h1>
        </div>
        <div className="legend">
          <div className="p-legend">
            <div className="planned-legend"></div>
            <strong>Planned Job</strong>
          </div>
          <div className="up-legend">
            <div className="unplanned-legend"></div>
            <strong>Unplanned Job</strong>
          </div>
        </div>
        <div className="company-logo">
          <img src={logo} alt="logo" width={80} />
          <img className="ms-2" src={logoText} alt="logo" width={100} />
        </div>
      </div>
      <div className="container-fluid">
        {isLoading ? (
          <div>
            <h1 className="text-center">Loading...</h1>
          </div>
        ) : (
          <div className="row">
            <div className="" style={{ maxHeight: "calc(100vh - 100px)", overflowY: "auto" }} ref={logRef}>
              {logList.map((item) => item.log_type === "Unplanned" ? (
                <div className="card unplanned-card" key={item.id}>
                  <div className="row border-b">
                  <div className="col-3">
                      <div className="heading-holder">
                        <span className="title-text">Date</span>
                        <span className="body-text">{item.created_at}</span>
                      </div>
                    </div>
                    {/* colend */}
                    <div className="col-3 border-both">
                      <div className="heading-holder">
                        <span className="title-text">Location</span>
                        <span className="body-text">{item.work_location.name}</span>
                      </div>
                    </div>
                    {/* colend */}

                    <div className="col-3 border-5">
                      <div className="heading-holder">
                        <span className="title-text">Maintenance Type</span>
                        <span className="body-text">{item.classification}</span>
                      </div>
                    </div>
                    {/* col end */}
                    <div className="col-3">
                      <div className="heading-holder">
                        <span className="title-text mb-2">Status</span>
                        <span className="body-text"><StatusButtonComponent status={item.status} /></span>
                      </div>
                    </div>

                    {/* col-end */}
                  </div>

                  <div className="mt-4">
                    <div className="update"><strong>Update: </strong>{item.maintenance_activity}</div>
                    <div className="problem"><strong>Problem: </strong>{item.problem_statement}</div>
                  </div>

                </div>
              ) : (
                <div className="card planned-card" key={item.id}>
                  <div className="row border-b">
                  <div className="col-3">
                      <div className="heading-holder">
                        <span className="title-text">Date</span>
                        <span className="body-text">{item.created_at}</span>
                      </div>
                    </div>
                    {/* colend */}
                    <div className="col-3 border-both">
                      <div className="heading-holder">
                        <span className="title-text">Location</span>
                        <span className="body-text">{item.work_location.name}</span>
                      </div>
                    </div>
                    {/* colend */}
                    <div className="col-3 border-r">
                      <div className="heading-holder">
                        <span className="title-text">Job</span>
                        <span className="body-text">{item.planned_job.name}</span>
                      </div>
                    </div>
                    {/* col end */}
                    <div className="col-3">
                      <div className="heading-holder">
                        <span className="title-text mb-2">Status</span>
                        <span className="body-text"><StatusButtonComponent status={item.status} /></span>
                      </div>
                    </div>

                    {/* col-end */}
                  </div>
                </div>
              )
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default JobActivity;


