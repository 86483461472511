import React, { useState } from 'react'
import { Modal, Form } from 'react-bootstrap'
import { toast } from "react-toastify";
import DynamicSelectInput from '../../../constant/SelectInput';
import { updatePlannedLogBook } from '../../../../services/LogBookService';
const InspctionUpdateModal = ({ data }) => {
    const initialInspectLogInput = { id: "", remark: "", check_list: [], material_list: [] };
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [inspectLogInput, setInspectLogInput] = useState(
        initialInspectLogInput
    );


    const toggleModal = () => {
        if (isModalOpen) {
            setInspectLogInput(initialInspectLogInput);
        } else if (data) {
            setInspectLogInput({
                ...inspectLogInput, id: data.id, check_list: data.check_list.map(item => {
                    return { ...item, value: "yes", defect: ""}
                })
            })
        }
        setIsModalOpen(!isModalOpen);
    };

    const handleChangeAdd = (e) => {
        const { name, value } = e.target;
        setInspectLogInput({ ...inspectLogInput, [name]: value });
    };

    const handleRadioChange = (index, value) => {
        const updatedItems = [...inspectLogInput.check_list];
        if (value === "yes") {
            updatedItems[index] = {
                ...updatedItems[index],
                value,
                defect: "",
            };
        } else {
            updatedItems[index] = { ...updatedItems[index], value };
        }

        setInspectLogInput({ ...inspectLogInput, check_list: updatedItems });
    };

    const handleOtherDefectChange = (index, value) => {
        const updatedItems = [...inspectLogInput.check_list];
        updatedItems[index] = { ...updatedItems[index], defect: value };
        setInspectLogInput({ ...inspectLogInput, check_list: updatedItems });
    };

    const validateForm = () => {
        let errors = {};
        const fieldsToValidate = ["remark"];
        fieldsToValidate.forEach((field) => {
            if (!inspectLogInput[field]) {
                errors[field] = true;
            }
        });
        return Object.keys(errors).length === 0;
    };

    const handleSelectChange = (name, value) => {
        setInspectLogInput({ ...inspectLogInput, [name]: value })
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!validateForm()) {
            return false;
        }

        try {
            const newData = {
                ...inspectLogInput,
                check_list: inspectLogInput.check_list.map((item) => {
                    return {
                        id: item.id,
                        is_ok: item.value,
                        defect: item?.defect || null,
                    };
                }),
            };
            const resp = await updatePlannedLogBook(newData);
            const resp_data = resp.data.data;
            setInspectLogInput(initialInspectLogInput);
            toast.success(resp.data.message);
            toggleModal();
        } catch (error) {
            toast.error(error.response.data.message);
        }
    };

    return (
        <div>
            {data && <button className='btn btn-outline-primary btn-sm' onClick={toggleModal}>Inspection</button>}
            <Modal
                show={isModalOpen}
                onHide={toggleModal}
                className="modal-lg"
                placement="end"
            >
                <div className="modal-content">
                    <div className="modal-header border-bottom">
                        <h5 className="modal-title" id="#gridSystemModal">
                            Complete Inspection
                        </h5>
                        <button
                            type="button"
                            className="btn-close"
                            onClick={toggleModal}
                        ></button>
                    </div>
                    <div className="modal-body">
                        <Form onSubmit={(e) => handleSubmit(e)}>
                            <div className="row">
                                {inspectLogInput.check_list.map((item, index) => (
                                    <div className="col-md-6 mb-4" key={index}>
                                        <div className="card">
                                            <div className="card-header py-1">
                                                <h5
                                                    className={`${item.value === "yes" &&
                                                        "text-decoration-line-through"
                                                        }`}
                                                >
                                                    {" "}
                                                    {item.name}
                                                </h5>
                                            </div>
                                            <div className="card-body">
                                                <div>
                                                    <div className="form-check form-check-inline">
                                                        <label className="form-check-label">Choose</label>
                                                    </div>
                                                    <div className="form-check form-check-inline">
                                                        <input
                                                            className="form-check-input"
                                                            type="radio"
                                                            onChange={() => handleRadioChange(index, "yes")}
                                                            name={`${item.name}`}
                                                            checked={item.value === "yes"}
                                                            id={`yes${index}`}
                                                            value="yes"
                                                        />
                                                        <label
                                                            className="form-check-label"
                                                            htmlFor={`yes${index}`}
                                                        >
                                                            <span className="">
                                                               <i className="fa-regular fa-square-check fa-lg text-success me-1"></i>Ok
                                                            </span>
                                                        </label>
                                                    </div>
                                                    <div className="form-check form-check-inline">
                                                        <input
                                                            className="form-check-input"
                                                            type="radio"
                                                            onChange={() => handleRadioChange(index, "no")}
                                                            name={`${item.name}`}
                                                            checked={item.value === "no"}
                                                            id={`no${index}`}
                                                            value="no"
                                                        />
                                                        <label
                                                            className="form-check-label"
                                                            htmlFor={`no${index}`}
                                                        >
                                                            <span className="">
                                                                <i className="fa-solid fa-square-xmark fa-lg text-danger me-1"></i> Not OK
                                                            </span>
                                                        </label>
                                                    </div>
                                                </div>
                                                <div className="d-flex flex-column">
                                                    {item.value === "no" ? (
                                                        <div className="w-100">
                                                            <input
                                                                type="text"
                                                                className="form-control mb-3"
                                                                required
                                                                onChange={(e) =>
                                                                    handleOtherDefectChange(
                                                                        index,
                                                                        e.target.value
                                                                    )
                                                                }
                                                                placeholder="Enter Defect"
                                                            />
                                                        </div>
                                                    ) : null}
                                                </div>
                                            </div>
                                        </div>
                                        {/* card end */}
                                    </div>
                                ))}
                                <div className="mb-3">
                                    <label htmlFor="remark" className="form-label">
                                        Remarks <span className="text-danger">*</span>
                                    </label>
                                    <textarea
                                        type="text"
                                        className={`form-control`}
                                        id="remark"
                                        rows={2}
                                        placeholder="Enter Remarks"
                                        value={inspectLogInput.remark}
                                        name="remark"
                                        onChange={handleChangeAdd}
                                    ></textarea>
                                </div>
                                {/* </div> */}
                            </div>
                            <DynamicSelectInput
                                setParentInputValue={handleSelectChange}
                                endpoint_name="search/material"
                                name={"material_list"}
                                id={"material_list"}
                                label={"Meterail / Spare Consumed"}
                                is_multi={true}
                                placeholder={"Select Material / Spare"}
                                isRequired={false}
                            />

                            <div className='d-flex align-items-center justify-content-center mt-3'>
                                <button type="submit" className="btn btn-success me-1">
                                    Complete
                                </button>
                                <button
                                    type="button"
                                    onClick={toggleModal}
                                    className="btn btn-danger light ms-1"
                                >
                                    Cancel
                                </button>
                            </div>
                        </Form>
                    </div>
                </div>
            </Modal>
        </div>
    )
}

export default InspctionUpdateModal