import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import {
  getLogBook,
  getPlannedLogBook,
} from "../../../services/LogBookService";
import { useNavigate } from "react-router-dom";
import UnPlannedShowLogBook from "./UnPlannedLogBook/showLogBook";
import PlannedShowLogBook from "./PlannedLogBook/showLogBook";
const ShowLogBook = () => {
  const { id } = useParams();
  const [data, setData] = useState(null);
  const [isPlannedLog, setIsPlannedLog] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (id) {
      if (id.startsWith("upl_")) {
        setIsPlannedLog(false);
        setIsLoading(true)
        getLogBook(id)
          .then((resp) => {
            const resp_data = resp.data.data;
            setData(resp_data);
            setIsLoading(false)
          })
          .catch((err) => {
            console.log(err);
            setIsLoading(false)
          });
      } else {
        setIsPlannedLog(true);
        setIsLoading(true)
        getPlannedLogBook(id)
          .then((resp) => {
            const resp_data = resp.data.data;
            setData(resp_data);
            setIsLoading(false)
          })
          .catch((err) => {
            console.log(err);
            setIsLoading(false)
          });
      }
    }
  }, [id]);

  const navigate = useNavigate();

  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <div className="col-xl-12">
            <div className="card">
              <div className="card-header">
                <div className="d-flex ">
                  <button
                    type="button"
                    className="badge badge-circle badge-primary"
                    onClick={() =>
                      navigate(
                        `/log-book${isPlannedLog ? "?state=planned" : ""}`
                      )
                    }
                  >
                    <i className="fa-solid fa-arrow-left"></i>
                  </button>
                  <h4 className="mb-0 ms-2">Log Details</h4>
                </div>
              </div>
              {isPlannedLog ? (
                <PlannedShowLogBook data={data} isLoading={isLoading} />
              ) : (
                <UnPlannedShowLogBook data={data} isLoading={isLoading} />
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ShowLogBook;
