import axiosInstance from "./AxiosInstance";
const apiUrl = "api/engineer";

export function getEngineerList(page, page_size, query) {
  return axiosInstance.get(
    `${apiUrl}?page=${page}&page_size=${page_size}&query=${query}`
  );
}

export function getEngineer(id) {
  return axiosInstance.get(`${apiUrl}/${id}`);
}

export function createEngineer(data) {
  return axiosInstance.post(apiUrl, data);
}

export function updateEngineer(data) {
  return axiosInstance.put(apiUrl, data);
}

export function deleteEngineer(id) {
  return axiosInstance.delete(`${apiUrl}/${id}`);
}
