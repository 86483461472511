import axiosInstance from "./AxiosInstance";
const apiUrl = "api/logbook";
const plannedLogBookUrl = "api/planned_logbook";

export function getLogBookList(
  page,
  page_size,
  query,
  job_type,
  shift_type,
  classification,
  status,
  start_date,
  end_date
) {
  return axiosInstance.get(
    `${apiUrl}?page=${page}&page_size=${page_size}&query=${query}&job_type=${job_type}&shift_type=${shift_type}&classification=${classification}&status=${status}&start_date=${start_date}&end_date=${end_date}`
  );
}

export function getLogBook(id) {
  return axiosInstance.get(`${apiUrl}/${id}`);
}

export function createLogBook(data) {
  return axiosInstance.post(apiUrl, data);
}

export function updateLogBook(data) {
  return axiosInstance.put(apiUrl, data);
}

export function deleteLogBook(id) {
  return axiosInstance.delete(`${apiUrl}/${id}`);
}
export function downloadLogBook(
  job_type,
  shift_type,
  classification,
  status,
  start_date,
  end_date
) {
  return axiosInstance.get(
    `api/unplanned_logbook_excel?job_type=${job_type}&shift_type=${shift_type}&classification=${classification}&status=${status}&start_date=${start_date}&end_date=${end_date}`
  );
}

//planned

export function getPlannedLogBookList(
  page,
  page_size,
  query,
  work_location,
  shift_type,
  planned_job,
  status,
  start_date,
  end_date
) {
  return axiosInstance.get(
    `${plannedLogBookUrl}?page=${page}&page_size=${page_size}&query=${query}&work_location=${work_location}&shift_type=${shift_type}&planned_job=${planned_job}&status=${status}&start_date=${start_date}&end_date=${end_date}`
  );
}

export function getPlannedLogBook(id) {
  return axiosInstance.get(`${plannedLogBookUrl}/${id}`);
}

export function createPlannedLogBook(data) {
  return axiosInstance.post(plannedLogBookUrl, data);
}

export function updatePlannedLogBook(data) {
  return axiosInstance.put(plannedLogBookUrl, data);
}

export function deletePlannedLogBook(id) {
  return axiosInstance.delete(`${plannedLogBookUrl}/${id}`);
}
export function downloadPlannedLogBook(
  work_location,
  shift_type,
  planned_job,
  status,
  start_date,
  end_date
) {
  return axiosInstance.get(
    `api/planned_logbook_excel?work_location=${work_location}&shift_type=${shift_type}&planned_job=${planned_job}&status=${status}&start_date=${start_date}&end_date=${end_date}`
  );
}
