import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Link } from "react-router-dom";
import { navtoggle } from "../../../store/actions/AuthActions";
import { connect } from "react-redux";
import InteliLogo from "../../../images/logo/logo_text.png";
import InteliIcon from "../../../images/logo/logo.png";

const NavHader = ({ role }) => {
  const [isMobileScreen, setIsMobileScreen] = useState(false);

  useEffect(() => {
    function handleResize() {
      setIsMobileScreen(window.innerWidth < 768);
    }
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  const dispatch = useDispatch();
  const sideMenu = useSelector((state) => state.sideMenu);
  const handleToogle = () => {
    dispatch(navtoggle());
  };
  return (
    <>
      <div className="nav-header border-bottom border-primary">
        <Link to="/dashboard" className="brand-logo">
          <img src={InteliIcon} alt="logo" style={{ width: "3rem" }} />
          <span className="brand-title">
            <img src={InteliLogo} alt="logo" style={{ width: "9rem" }} />
          </span>
        </Link>
        {isMobileScreen && role === "Employee" ? (
          " "
        ) : (
          <div
            className="nav-control"
            onClick={() => {
              handleToogle();
            }}
          >
            <div className={`hamburger ${sideMenu ? "is-active" : ""}`}>
              <span className="line"></span>
              <span className="line"></span>
              <span className="line"></span>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    role: state.auth.user?.role || "Employee",
  };
};

export default connect(mapStateToProps)(NavHader);
