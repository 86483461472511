import React, { useState, useEffect } from "react";
import ReactApexChart from "react-apexcharts";
import DataLoader from "../Loader";

const ShiftLogEquipmentPieCard = ({ data, isLoading }) => {
  const [label, setLabel] = useState([])
  const [series, setSeries] = useState([])

  useEffect(() => {
    if (data) {
      setLabel(data.map(item => item.work_location__name) )
      setSeries(data.map(item => item.count) )
    }

  }, [data])

  const options = {
    chart: {
      width: '100%',
      type: 'polarArea',
      height: 300,
    },
    labels: label,
    plotOptions: {
      pie: {
        dataLabels: {
          offset: -5
        }
      }
    },

    dataLabels: {
      formatter(val, opts) {
        const name = opts.w.globals.labels[opts.seriesIndex]
        return [name]
      }
    },
    legend: {
      show: true
    }
  }

  return (
    <div className="card" >
      <div className="card-header border-0 pb-0 flex-wrap">
        <h4 className="heading mb-0">Most Occurred Location</h4>

      </div>
      <div className="card-body  p-0">
        {!isLoading ?
          <div id="shifteqipmentpiechart">
            <ReactApexChart
              options={options}
              series={series}
              type="polarArea"
              height={300}
            />
          </div>
          : <DataLoader />
        }
      </div>
    </div>
  );

}

export default ShiftLogEquipmentPieCard;