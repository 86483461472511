import React, { useState } from 'react'
import { connect, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom'
import { loadingToggleAction, loginAction } from '../../store/actions/AuthActions';
import { Spinner } from 'react-bootstrap';

import logo from '../../images/logo/intelilogshiftTextLogo.png';
import bg6 from '../../images/background/loginillustrator.jpg'

function Login({ showLoading }) {
	const navigate = useNavigate();
	const [personnelNumber, setPersonnelNumber] = useState('');
	let errorsObj = { personnelNumber: '', password: '' };
	const [errors, setErrors] = useState(errorsObj);
	const [password, setPassword] = useState('');
	const dispatch = useDispatch();

	function onLogin(e) {

		e.preventDefault();
		if (!showLoading) {
			let error = false;
			const errorObj = { ...errorsObj };
			if (personnelNumber === '') {
				errorObj.personnelNumber = 'Personnel number is Required';
				error = true;
			}
			if (password === '') {
				errorObj.password = 'Password is Required';
				error = true;
			}
			setErrors(errorObj);
			if (error) {
				return;
			}
			dispatch(loadingToggleAction(true));
			dispatch(loginAction(personnelNumber, password, navigate));
		}
	}
	const loginBodyStyle = {
		backgroundImage: `url(${bg6})`,
		backgroundPosition: 'center',
		backgroundSize: 'cover',
		height: "100vh"
	};

	return (
		<div className="page-wraper">
			<div className="browse-job login-style3">
				<div className="bg-img-fix overflow-hidden" style={loginBodyStyle}>
					<div className="d-flex align-items-center justify-content-ceiner h-100">
					<div className="login-form style-2">
									<div className="card-body">
										<div style={{ marginBottom: '2rem', display: 'flex', alignItems: 'center', justifyContent: 'center' }} >
											<img src={logo} alt="" width={'200px'} className="width-200 light-logo" />
										</div>
										<div className="nav nav-tabs border-bottom-0" >
											<div className="tab-content w-100" id="nav-tabContent">
												<div className="tab-pane fade active show" id="nav-personal">
													<form className=" dz-form pb-3" onSubmit={onLogin}>
														<h3 className="form-title text-center m-t0">Login Your Account</h3>
														<div className="dz-separator-outer m-b5">
															<div className="dz-separator bg-primary style-liner"></div>
														</div>
														<p>Enter your personnel number and your password. </p>
														<div className="form-group mb-3">
															<label>Personnel Number</label>
															<input type="text" className="form-control"
																placeholder='Enter Personnel Number'
																value={personnelNumber} onChange={(e) => setPersonnelNumber(e.target.value)} />
															{errors.personnelNumber && <div className="text-danger fs-12">{errors.personnelNumber}</div>}
														</div>
														<div className="form-group mb-3">
															<label>Password</label>
															<input type="password" className="form-control"
																placeholder='Enter Password'
																value={password} onChange={(e) => setPassword(e.target.value)} />
															{errors.password && <div className="text-danger fs-12">{errors.password}</div>}
														</div>

														<button type="submit" disabled={showLoading} className="btn btn-primary btn-block dz-xs-flex m-r5">
															{showLoading ? <><Spinner size="sm" /> Login</> : "Login"}</button>
													</form>
												</div>

											</div>
										</div>
									</div>
								</div>
					</div>
				</div>
			</div>
		</div>
	)
}

const mapStateToProps = (state) => {
	return {
		showLoading: state.auth.showLoading,
	};
};
export default connect(mapStateToProps)(Login);