import { Suspense, useEffect } from "react";

/// Components
import Index from "./jsx";
import { useDispatch } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
// action
import {
  checkAutoLoginAction,
  authCheckConfirmAction,
  updateMobileScreenAction,
} from "./store/actions/AuthActions";
/// Style
import "./other/bootstrap-select/dist/css/bootstrap-select.min.css";
import "./css/style.css";

function App() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  useEffect(() => {
    if (location.pathname === "/activity") {
      dispatch(authCheckConfirmAction(false));
    } else {
      dispatch(checkAutoLoginAction(navigate));
    }
  }, []);

  useEffect(() => {
    function handleResize() {
      dispatch(updateMobileScreenAction(window.innerWidth < 768));
    }
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <Suspense
      fallback={
        <div id="preloader">
          <div className="sk-three-bounce">
            <div className="sk-child sk-bounce1"></div>
            <div className="sk-child sk-bounce2"></div>
            <div className="sk-child sk-bounce3"></div>
          </div>
        </div>
      }
    >
      <Index />
    </Suspense>
  );
}

export default App;
