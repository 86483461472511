import React, { useState, useEffect } from "react";
import Pagination from "../../constant/Pagination";
import { toast } from "react-toastify";
import { connect } from "react-redux";
import { Form, Modal, Spinner } from "react-bootstrap";
import { CancelRequest } from "../../../services/AxiosInstance";
import {
  getPlannedJobScheduleList,
  createPlannedJobSchedule,
} from "../../../services/PlannedJobService";
import { StaticSelectInput } from "../../constant/SelectInput";
import TableLoaderEffect from "../../constant/TableLoader";

const daysOption = [
  { label: "Sun", value: "sun" },
  { label: "Mon", value: "mon" },
  { label: "Tue", value: "tue" },
  { label: "Wed", value: "wed" },
  { label: "Thu", value: "thu" },
  { label: "Fri", value: "fri" },
  { label: "Sat", value: "sat" },
];

const monthsOption = [
  { label: "Jan", value: "jan" },
  { label: "Feb", value: "feb" },
  { label: "Mar", value: "mar" },
  { label: "Apr", value: "apr" },
  { label: "May", value: "may" },
  { label: "Jun", value: "jun" },
  { label: "Jul", value: "jul" },
  { label: "Aug", value: "aug" },
  { label: "Sep", value: "sep" },
  { label: "Oct", value: "oct" },
  { label: "Nov", value: "nov" },
  { label: "Dec", value: "dec" },
];

const DatesOption = Array.from({ length: 31 }, (_, index) => ({
  label: `${index + 1 < 10 ? "0" : ""}${index + 1}`,
  value: `${index + 1 < 10 ? "0" : ""}${index + 1}`,
}));
const hourOption = Array.from({ length: 23 }, (_, index) => ({
  label: `${index + 1 < 10 ? "0" : ""}${index + 1}`,
  value: `${index + 1 < 10 ? "0" : ""}${index + 1}`,
}));
const minuteOption = Array.from({ length: 59 }, (_, index) => ({
  label: `${index + 1 < 10 ? "0" : ""}${index + 1}`,
  value: `${index + 1 < 10 ? "0" : ""}${index + 1}`,
}));

const ScheduleTab = ({ isMobileScreen }) => {
  const initialInspectionInput = {
    name: "",
    schedule_type: "Weekly",
    inspect_hour: "",
    inspect_minute: "",
    day_of_week: [],
  };
  const initialInspectionInputError = {
    name: false,
    schedule_type: false,
    inspect_hour: false,
    inspect_minute: false,
    day_of_week: false,
  };
  const [currentPage, setCurrentPage] = useState(1);
  const [searchQuery, setSearchQuery] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isViewModalOpen, setIsViewModalOpen] = useState(false);
  const [isSearchLoading, setIsSearchLoading] = useState(false);
  const [isSubmitLoading, setIsSubmitLoading] = useState(false);
  const [inspectionInput, setInspectionInput] = useState(
    initialInspectionInput
  );
  const [inspectionInputError, setInspectionInputError] = useState(
    initialInspectionInputError
  );
  const [data, setData] = useState([]);
  const [total, setTotal] = useState(0);
  const [selectedInspect, setSelectedInspect] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const recordsPage = 10;

  useEffect(() => {
    if (searchQuery.length > 0) {
      CancelRequest();
    } else {
      setIsLoading(true);
    }
    getPlannedJobScheduleList(currentPage, recordsPage, searchQuery)
      .then((resp) => {
        setData(resp.data.data);
        setTotal(resp.data.total);
        setIsLoading(false);
        setIsSearchLoading(false);
      })
      .catch((error) => {
        if (error.response) {
          console.log(error.response.data.message);
          setIsLoading(false);
          setIsSearchLoading(false);
        } else {
          console.log(error);
        }
      });
  }, [currentPage, searchQuery]);

  const toggleViewModal = () => {
    if (isViewModalOpen) {
      setSelectedInspect(null);
    }
    setIsViewModalOpen(!isViewModalOpen);
  };

  const toggleModal = () => {
    if (isModalOpen) {
      setInspectionInput(initialInspectionInput);
      setInspectionInputError(initialInspectionInputError);
    }
    setIsModalOpen(!isModalOpen);
  };

  const handleSearchChange = (e) => {
    const { value } = e.target;
    setSearchQuery(value);
    setIsSearchLoading(true);
    setCurrentPage(1);
  };

  const handlePagination = (page) => {
    setIsLoading(true);
    const new_page = page.selected + 1;
    setCurrentPage(new_page);
  };

  const handleChangeAdd = (e) => {
    const { name, value } = e.target;
    if (name === "schedule_type") {
      try {
        if (value === "Weekly") {
          const {
            day_of_month: ommitday_of_month,
            month_of_year: ommitmonth_of_year,
            ...newInspectionInput
          } = inspectionInput;
          setInspectionInput({
            ...newInspectionInput,
            day_of_week: [],
            schedule_type: value,
          });
          setInspectionInputError({ ...inspectionInputError, [name]: false });
        } else if (value === "Monthly") {
          const {
            day_of_week: ommitday_of_week,
            month_of_year: ommitmonth_of_year,
            ...newInspectionInput
          } = inspectionInput;
          setInspectionInput({
            ...newInspectionInput,
            day_of_month: [],
            schedule_type: value,
          });
          setInspectionInputError({ ...inspectionInputError, [name]: false });
        } else {
          const { day_of_week: ommitday_of_week, ...newInspectionInput } =
            inspectionInput;
          setInspectionInput({
            ...newInspectionInput,
            day_of_month: [],
            month_of_year: [],
            schedule_type: value,
          });
          setInspectionInputError({ ...inspectionInputError, [name]: false });
        }
      } catch (error) {
        console.log("error", error);
      }
    } else {
      setInspectionInput({ ...inspectionInput, [name]: value });
      setInspectionInputError({ ...inspectionInputError, [name]: false });
    }
  };

  const handleSelectChange = (name, value) => {
    setInspectionInput({ ...inspectionInput, [name]: value });
    if (inspectionInputError.hasOwnProperty(name) && value) {
      setInspectionInputError({ ...inspectionInputError, [name]: false });
    }
  };

  const validateForm = () => {
    let errors = {};
    Object.entries(inspectionInput).forEach(([key, value]) => {
      if (!value || (Array.isArray(value) && value.length === 0)) {
        errors[key] = true;
      }
    });
    setInspectionInputError({ ...inspectionInputError, ...errors });
    return Object.keys(errors).length === 0;
  };

  const handleSubmit = async (e) => {
    // setInspectionInputError({ ...inspectionInputError, schedule_type: false });
    e.preventDefault();
    if (!validateForm()) {
      return false;
    }
    try {
      setIsSubmitLoading(true);
      const {
        inspect_hour: ispHr,
        inspect_minute: ispMin,
        ...newInspectionInput
      } = inspectionInput;
      const formData = {
        schedule_time: `${ispHr}:${ispMin}`,
        ...newInspectionInput,
      };
      const resp = await createPlannedJobSchedule(formData);
      const resp_data = resp.data.data;
      setData([resp_data, ...data]);
      setTotal(total + 1);
      toast.success(resp.data.message);
      toggleModal();
    } catch (error) {
      toast.error(error.response.data.message);
    }
    finally {
      setIsSubmitLoading(false);
    }
  };

  return (
    <>
      <div className="card">
        <div className="card-body p-0">
          <div className="table-responsive active-projects style-1 ItemsCheckboxSec shorting">
            <div className="tbl-caption d-flex justify-content-between text-wrap align-items-center">
              <h4 className="heading mb-0 fw-bold">Schedule List</h4>
              <div className="d-flex align-items-start align-items-sm-center flex-column flex-sm-row">
                <div className="me-3 mb-3 mb-md-0">
                  <div className="input-group tp-input-group">
                    <input
                      type="text"
                      className="form-control border-end-0"
                      placeholder="Search"
                      value={searchQuery}
                      aria-describedby="basic-search"
                      onChange={handleSearchChange}
                    />
                    <div
                      className="input-group-text bg-white border-primary"
                      id="basic-search"
                    >
                      {isSearchLoading && (
                        <Spinner variant="primary" size="sm" />
                      )}
                      {!isSearchLoading && searchQuery.length > 0 && (
                        <i
                          className="fa-solid fa-xmark c-pointer text-primary"
                          onClick={() => {
                            setCurrentPage(1);
                            setSearchQuery("");
                          }}
                        ></i>
                      )}
                    </div>
                  </div>
                </div>
                <button
                  className="btn btn-primary"
                  onClick={() => toggleModal()}
                >
                  Add CheckList
                </button>
              </div>
            </div>
            {isMobileScreen ? (
              <div className="row">
                {data.map((item, index) => (
                  <div className="col-sm-6" key={index}>
                    <div
                      class="card"
                      onClick={() => {
                        setSelectedInspect(item);
                        toggleViewModal();
                      }}
                    >
                      <div class="card-body">
                        <p class="card-text">
                          <strong>Name: </strong>
                          <span className="text-primary cursor-pointer fw-bolder">
                            {item.name}
                          </span>
                        </p>
                        <p class="card-text">
                          <strong>Schedule Type: </strong>
                          <span>{item.schedule_type}</span>
                        </p>
                        <p class="card-text">
                          <strong>Schedule Time: </strong>
                          <span>{item.schedule_time}</span>
                        </p>
                        <p class="card-text">
                          <strong>Created At: </strong>
                          <span>{item.created_at}</span>
                        </p>
                        <p class="card-text">
                          <strong>Create By: </strong>
                          <span>{item.created_by?.name}</span>
                        </p>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            ) : (
              <div
                id="employee-tbl_wrapper"
                className="dataTables_wrapper no-footer"
              >
                <table
                  id="empoloyees-tblwrapper"
                  className="table ItemsCheckboxSec dataTable no-footer mb-0"
                >
                  <thead>
                    <tr>
                      <th>Name</th>
                      <th>Schedule Type</th>
                      <th>Schedule Time</th>
                      <th>Created At</th>
                      <th>Create By</th>
                    </tr>
                  </thead>
                  <tbody>
                    {isLoading ? (
                      <TableLoaderEffect colLength={6} />
                    ) : (
                      data.map((item, index) => (
                        <tr key={index}>
                          <td>
                            <span
                              className="text-primary cursor-pointer fw-bolder"
                              onClick={() => {
                                setSelectedInspect(item);
                                toggleViewModal();
                              }}
                            >
                              {item.name}
                            </span>
                          </td>
                          <td>
                            <span>{item.schedule_type}</span>
                          </td>
                          <td>
                            <span>{item.schedule_time}</span>
                          </td>
                          <td>
                            <span>{item.created_at}</span>
                          </td>
                          <td>
                            <span>{item.created_by?.name}</span>
                          </td>
                        </tr>
                      ))
                    )}
                  </tbody>
                </table>

                {isLoading ? (
                  <div style={{ height: "4rem" }}></div>
                ) : (
                  <Pagination
                    currentPage={currentPage}
                    recordsPage={recordsPage}
                    dataLength={total}
                    handlePagination={handlePagination}
                  />
                )}
              </div>
            )}
          </div>
        </div>
      </div>

      <Modal
        show={isModalOpen}
        onHide={toggleModal}
        className="modal fade modal-md"
        placement="end"
      >
        <div className="modal-conten">
          <div className="modal-header border-bottom">
            <h5 className="modal-title" id="#gridSystemModal">
              Add Schedule
            </h5>
            <button
              type="button"
              className="btn-close"
              onClick={toggleModal}
            ></button>
          </div>

          <div className="modal-body">
            <Form onSubmit={(e) => handleSubmit(e)}>
              <div className="row">
                <div className="col-xl-12">
                  <div className="mb-3">
                    <label className="form-check-label">
                      Schedule Name <span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      className={`form-control ${
                        inspectionInputError.name ? "is-invalid" : ""
                      } `}
                      id="name"
                      name="name"
                      placeholder="Enter Schedule Name"
                      onChange={handleChangeAdd}
                    />
                  </div>
                  <div className="form-group">
                    <div>
                      <label className="form-check-label">
                        Schedule Type <span className="text-danger">*</span>
                      </label>
                    </div>
                    <div className="form-check custom-checkbox mb-2 form-check-inline">
                      <input
                        type="radio"
                        className="form-check-input"
                        id="Weekly-inpsect"
                        name="schedule_type"
                        value={"Weekly"}
                        onChange={handleChangeAdd}
                        checked={inspectionInput.schedule_type === "Weekly"}
                      />
                      <label
                        className="form-check-label"
                        htmlFor="Weekly-inpsect"
                      >
                        Weekly
                      </label>
                    </div>
                    <div className="form-check custom-checkbox mb-2 form-check-inline">
                      <input
                        type="radio"
                        className="form-check-input"
                        id="Monthly-inpsect"
                        name="schedule_type"
                        value={"Monthly"}
                        onChange={handleChangeAdd}
                        checked={inspectionInput.schedule_type === "Monthly"}
                      />
                      <label
                        className="form-check-label"
                        htmlFor="Monthly-inpsect"
                      >
                        Monthly
                      </label>
                    </div>
                    <div className="form-check custom-checkbox mb-2 form-check-inline">
                      <input
                        type="radio"
                        className="form-check-input"
                        id="Yearly-inpsect"
                        name="schedule_type"
                        value={"Yearly"}
                        onChange={handleChangeAdd}
                        checked={inspectionInput.schedule_type === "Yearly"}
                      />
                      <label
                        className="form-check-label"
                        htmlFor="Yearly-inpsect"
                      >
                        Yearly
                      </label>
                    </div>
                  </div>

                  {inspectionInput.schedule_type === "Weekly" ? (
                    <StaticSelectInput
                      setParentInputValue={handleSelectChange}
                      optionsList={daysOption}
                      name={"day_of_week"}
                      id={"day_of_week"}
                      label={"Day Of Week"}
                      placeholder={"Select Day Of Week"}
                      isRequired={true}
                      is_multi={true}
                      is_invalid={inspectionInputError.day_of_week}
                    />
                  ) : null}

                  {inspectionInput.schedule_type === "Monthly" ? (
                    <StaticSelectInput
                      setParentInputValue={handleSelectChange}
                      optionsList={DatesOption}
                      name={"day_of_month"}
                      id={"day_of_month"}
                      label={"Day of Month"}
                      placeholder={"Select Day Of Month"}
                      isRequired={true}
                      is_multi={true}
                      is_invalid={inspectionInputError.day_of_month}
                    />
                  ) : null}

                  {inspectionInput.schedule_type === "Yearly" ? (
                    <>
                      <StaticSelectInput
                        setParentInputValue={handleSelectChange}
                        optionsList={DatesOption}
                        name={"day_of_month"}
                        id={"day_of_month"}
                        label={"Day of Month"}
                        placeholder={"Select Day Of Month"}
                        isRequired={true}
                        is_multi={true}
                        is_invalid={inspectionInputError.day_of_month}
                      />

                      <StaticSelectInput
                        setParentInputValue={handleSelectChange}
                        optionsList={monthsOption}
                        name={"day_of_week"}
                        id={"month_of_year"}
                        label={"Month Of year"}
                        placeholder={"Select Month Of year"}
                        isRequired={true}
                        is_multi={true}
                        is_invalid={inspectionInputError.month_of_year}
                      />
                    </>
                  ) : null}

                  <div>
                    <div className="d-flex align-items-center">
                      <div>
                        <label className="form-check-label">
                          Schedule Time:
                        </label>
                      </div>
                      <StaticSelectInput
                        parentClassName={"mb-3 ms-2"}
                        setParentInputValue={handleSelectChange}
                        optionsList={hourOption}
                        name={"inspect_hour"}
                        label={"Hour"}
                        id={"inspect_hour"}
                        placeholder={"Select Hour"}
                        isRequired={true}
                        is_invalid={inspectionInputError.inspect_hour}
                      />
                      <StaticSelectInput
                        parentClassName={"mb-3 ms-2"}
                        setParentInputValue={handleSelectChange}
                        optionsList={minuteOption}
                        name={"inspect_minute"}
                        id={"inspect_minute"}
                        placeholder={"Select Minute"}
                        isRequired={true}
                        label={"Minute"}
                        is_invalid={inspectionInputError.inspect_minute}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="mt-3">
              <button
                  type="submit"
                  className="btn btn-block btn-sm btn-primary mt-3 float-end"
                  disabled={isSubmitLoading}
                >
                  {isSubmitLoading ? (
                    <span>
                      <Spinner variant="light" size="sm" className="me-2" />
                      Creating...
                    </span>
                  ) : (
                    "Create"
                  )}
                </button>
                <button
                  type="button"
                  onClick={toggleModal}
                  className="btn btn-danger light ms-1"
                >
                  Cancel
                </button>
              </div>
            </Form>
          </div>
        </div>
      </Modal>

      <Modal show={isViewModalOpen} onHide={toggleViewModal}>
        <div className="modal-header">
          <h4 className="modal-title my-1" id="#gridSystemModal">
            Schedule Details
          </h4>
          <button
            type="button"
            className="btn-close mx-3"
            onClick={toggleViewModal}
          ></button>
        </div>
        <div className="modal-body">
          {selectedInspect ? (
            <ul>
              <li className="my-3">
                <strong>Name:</strong> {selectedInspect.name}
              </li>
              <li>
                <strong>Schedule Type:</strong>
                {selectedInspect.schedule_type}
              </li>
              <li className="my-3">
                <strong>Schedule Time:</strong>
                {selectedInspect.schedule_time}
              </li>
              <li className="my-3">
                <strong>Created At:</strong> {selectedInspect.created_at}
              </li>
              <li className="my-3">
                <strong>Created By:</strong>
                {selectedInspect.created_by?.name}
              </li>
              <li>
                <span className="fw-bold">
                  {selectedInspect.schedule_type === "Yearly"
                    ? "Day of Month:"
                    : null}
                </span>

                {selectedInspect.schedule_type === "Yearly"
                  ? selectedInspect.day_of_month.map((item) => (
                      <span
                        className="badge badge-rounded badge-outline-primary m-1"
                        key={item}
                      >
                        {item}
                      </span>
                    ))
                  : null}
              </li>
              <li>
                <span className="fw-bold">
                  {selectedInspect.schedule_type === "Weekly"
                    ? "Day of Week"
                    : selectedInspect.schedule_type === "Monthly"
                    ? "Day of Month"
                    : "Month of year"}
                  :
                </span>{" "}
                {selectedInspect.schedule_type === "Weekly"
                  ? selectedInspect.day_of_week.map((item) => (
                      <span
                        className="badge badge-rounded badge-outline-primary m-1"
                        key={item}
                      >
                        {item.toUpperCase()}
                      </span>
                    ))
                  : selectedInspect.schedule_type === "Monthly"
                  ? selectedInspect.day_of_month.map((item) => (
                      <span
                        className="badge badge-rounded badge-outline-primary m-1"
                        key={item}
                      >
                        {item}
                      </span>
                    ))
                  : selectedInspect.month_of_year.map((item) => (
                      <span
                        className="badge badge-rounded badge-outline-primary m-1"
                        key={item}
                      >
                        {item}
                      </span>
                    ))}
              </li>
            </ul>
          ) : null}
        </div>
      </Modal>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    isMobileScreen: state.auth.isMobileScreen,
  };
};

export default connect(mapStateToProps)(ScheduleTab);
