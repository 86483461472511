import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import * as XLSX from "xlsx";
import {
  downloadLogBook,
  downloadPlannedLogBook,
} from "../../../services/LogBookService";

function ExcelLogBook({ logType, filterData }) {
  const [showModal, setShowModal] = useState(false);
  const [downloading, setDownloading] = useState(false);
  const toggleModal = () => {
    if (showModal) {
      setDownloading(false);
    }
    setShowModal(!showModal);
  };

  const convertToExcel = (data) => {
    try {
      const ws = XLSX.utils.json_to_sheet(data);
      // Add hyperlinks to IDs
      for (let i = 0; i < data.length; i++) {
        const hyperlink = {
          v: data[i].id,
          l: {
            Target: "http://localhost:3000/log-book-view/" + data[i].id,
            Tooltip: "Click to view log",
          },
        };
        ws[XLSX.utils.encode_cell({ r: i + 1, c: 0 })] = hyperlink;
      }

      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, "Sheet1");

      const fileName = `data_${new Date().getTime()}.xlsx`;

      const wbout = XLSX.write(wb, { bookType: "xlsx", type: "array" });
      const blob = new Blob([wbout], { type: "application/octet-stream" });

      const url = window.URL.createObjectURL(blob);

      const a = document.createElement("a");
      a.href = url;
      a.download = fileName;
      document.body.appendChild(a);
      a.click();

      window.URL.revokeObjectURL(url);
      document.body.removeChild(a);
    } catch (error) {
      console.error("Error converting data to Excel:", error);
    } finally {
      toggleModal();
    }
  };

  const handleApply = () => {
    setDownloading(true);
    if (logType) {
      downloadLogBook(
        filterData.job_type,
        filterData.shift_type,
        filterData.classification,
        filterData.status,
        filterData.start_date,
        filterData.end_date
      )
        .then((resp) => {
          convertToExcel(resp.data.data);
        })
        .catch((err) => {
          console.error("Error downloading data:", err);
        });
    } else {
      setDownloading(true);
      downloadPlannedLogBook(
        filterData.work_location,
        filterData.shift_type,
        filterData.planned_job,
        filterData.status,
        filterData.start_date,
        filterData.end_date
      )
        .then((resp) => {
          convertToExcel(resp.data.data);
        })
        .catch((err) => {
          console.error("Error downloading data:", err);
        });
    }
  };

  return (
    <>
      <button
        className="btn btn-outline-success btn-sm me-2"
        onClick={toggleModal}
      >
        <span className="d-flex align-items-center">
          <i className="fa-solid fa-file-excel fa-xl me-3"></i>{" "}
          <span>Export</span>
        </span>
      </button>

      <Modal show={showModal} onHide={toggleModal}>
        <div className="modal-content">
          <div className="modal-header">
            <h4 className="modal-title my-1" id="#gridSystemModal">
              Export To Excel
            </h4>
            <button
              type="button"
              className="btn-close mx-3"
              onClick={toggleModal}
            ></button>
          </div>
          <div className="modal-body">
            {logType ? (
              <div>
                <h5>Filter</h5>
                <ul>
                  {filterData.job_type ? (
                    <li className="my-3">
                      <strong>Job Type: </strong> {filterData.job_type}
                    </li>
                  ) : null}
                  {filterData.shift_type ? (
                    <li className="my-3">
                      <strong>Shift Type: </strong> {filterData.shift_type}
                    </li>
                  ) : null}
                  {filterData.classification ? (
                    <li className="my-3">
                      <strong>Classification: </strong>{" "}
                      {filterData.classification}
                    </li>
                  ) : null}
                  {filterData.status ? (
                    <li className="my-3">
                      <strong>Status: </strong> {filterData.status}
                    </li>
                  ) : null}
                  {filterData.start_date ? (
                    <li className="my-3">
                      <strong>Start Period: </strong> {filterData.start_date}
                    </li>
                  ) : null}
                  {filterData.end_date ? (
                    <li className="my-3">
                      <strong>End Period: </strong> {filterData.end_date}
                    </li>
                  ) : null}
                </ul>
              </div>
            ) : (
              <div>
                <ul>
                  {filterData.work_location ? (
                    <li className="my-3">
                      <strong>Work Location: </strong>{" "}
                      {filterData.work_location}
                    </li>
                  ) : null}
                  {filterData.shift_type ? (
                    <li className="my-3">
                      <strong>Shift Type: </strong> {filterData.shift_type}
                    </li>
                  ) : null}
                  {filterData.planned_job ? (
                    <li className="my-3">
                      <strong>Planned Job: </strong> {filterData.planned_job}
                    </li>
                  ) : null}
                  {filterData.status ? (
                    <li className="my-3">
                      <strong>Status: </strong> {filterData.status}
                    </li>
                  ) : null}
                  {filterData.start_date ? (
                    <li className="my-3">
                      <strong>Start Period: </strong> {filterData.start_date}
                    </li>
                  ) : null}
                  {filterData.end_date ? (
                    <li className="my-3">
                      <strong>End Period: </strong> {filterData.end_date}
                    </li>
                  ) : null}
                </ul>
              </div>
            )}
            <div className="mt-4">
              <button
                type="button"
                className="btn btn-primary me-1"
                onClick={handleApply}
              >
                {downloading ? (
                  <span>
                    Downloading...{" "}
                    <span
                      class="spinner-border text-light spinner-border-sm"
                      role="status"
                      aria-hidden="true"
                    ></span>
                  </span>
                ) : (
                  <span>Download</span>
                )}{" "}
              </button>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
}

export default ExcelLogBook;
