import axiosInstance from "./AxiosInstance";
const apiUrl = "api/material";

export function getMaterialList(page, page_size, query) {
  return axiosInstance.get(
    `${apiUrl}?page=${page}&page_size=${page_size}&query=${query}`
  );
}

export function getMaterial(id) {
  return axiosInstance.get(`${apiUrl}/${id}`);
}

export function createMaterial(data) {
  return axiosInstance.post(apiUrl, data);
}

export function updateMaterial(data) {
  return axiosInstance.put(apiUrl, data);
}

export function deleteMaterial(id) {
  return axiosInstance.delete(`${apiUrl}/${id}`);
}
