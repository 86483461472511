import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { Form, Modal } from "react-bootstrap";
import { getLogBook, updateLogBook } from "../../../../services/LogBookService";
import DynamicSelectInput, {
  StaticSelectInput,
} from "../../../constant/SelectInput";
import { useNavigate } from "react-router-dom";
const initialLogInput = {
  id: "",
  shift_type: "",
  work_location_id: "",
  classification: "",
  job_type: "Routine",
  problem_statement: [{ id: "", summary: "", description: "" }],
  maintenance_activity: [{ id: "", activity: "" }],
  comments: [{ id: "", text: "" }],
  occurred_at: "",
  executed_by_id: "",
  job_started_at: "",
  job_ended_at: "",
  material_consumed_list: [],
};
const EditLogBook = () => {
  const { id } = useParams();
  const [logFormInput, setLogFormInput] = useState(initialLogInput);
  const [data, setData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (id) {
      setIsLoading(true);
      getLogBook(id)
        .then((resp) => {
          const data = resp.data.data;
          console.log("data", data);
          setLogFormInput({
            id: data.id,
            shift_type: data.shift_type,
            work_location_id: data.work_location.id,
            classification: data.classification,
            job_type: data.job_type,
            problem_statement: data.problem_statement,
            maintenance_activity: data.maintenance_activity,
            comments: data.comments,
            occurred_at: formatDate(data.occurred_at),
            executed_by_id: data.executed_by ? data.executed_by.id : "",
            job_started_at: data.job_started_at
              ? formatDate(data.job_started_at)
              : "",
            job_ended_at: data.job_ended_at
              ? formatDate(data.job_ended_at)
              : "",
            material_consumed_list: data.material.map((item) => item.id),
          });
          setData(data);
        })
        .catch((err) => {
          console.log(err);
        });
      setIsLoading(true);
    }
  }, [id]);

  function formatDate(inputDateString) {
    const [day, month, year, time] = inputDateString.split(" ");
    const [hours, minutes] = time.split(":");
    const monthNumber = (
      "0" +
      (new Date(Date.parse(month + " 1, 2000")).getMonth() + 1)
    ).slice(-2);
    const newHours =
      hours === "12" && time.includes("AM")
        ? "00"
        : hours < 12 && time.includes("PM")
        ? +hours + 12
        : hours;

    return `${year}-${monthNumber}-${day}T${newHours}:${minutes}`;
  }

  const navigate = useNavigate();

  const handleChange = (index, field, subField, value) => {
    setLogFormInput((prevState) => {
      const newState = { ...prevState };
      newState[field][index][subField] = value;
      return newState;
    });
  };

  const addProblemStatement = (index) => {
    if (
      logFormInput["problem_statement"].every(
        (element) => element.summary && element.description
      )
    ) {
      setLogFormInput({
        ...logFormInput,
        problem_statement: [
          ...logFormInput.problem_statement,
          { id: "", summary: "", description: "" },
        ],
      });
    } else {
      const index = logFormInput["problem_statement"].findIndex(
        (element) => !element.summary || !element.description
      );
      const list = [...logFormInput.problem_statement];
      if (!list[index].summary) {
        document
          .getElementById(`summary-${index}`)
          ?.classList.add("is-invalid");
      }
      if (!list[index].description) {
        document
          .getElementById(`description-${index}`)
          ?.classList.add("is-invalid");
      }
    }
  };

  const addCommentMaintenance = (index, field_name, field) => {
    console.log("fff", logFormInput);
    if (logFormInput[field_name].every((element) => element[field])) {
      setLogFormInput({
        ...logFormInput,
        [field_name]: [...logFormInput[field_name], { id: "", [field]: "" }],
      });
    } else {
      const index = logFormInput[field_name].findIndex(
        (element) => !element[field]
      );
      console.log("index", index, field_name, field);
      const list = [...logFormInput[field_name]];
      if (!list[index][field]) {
        document
          .getElementById(`${field}-${index}`)
          ?.classList.add("is-invalid");
      }
    }
  };

  const removeTextFiled = (index, field_name) => {
    if (logFormInput[field_name].length > 1) {
      const list = [...logFormInput[field_name]];
      list.splice(index, 1);
      setLogFormInput({ ...logFormInput, [field_name]: list });
    }
  };

  const handleChangeAdd = (e) => {
    const { name, value } = e.target;
    if (name === "job_type" && value === "Routine") {
      setLogFormInput({ ...logFormInput, [name]: value, executed_by_id: "" });
    } else {
      setLogFormInput({ ...logFormInput, [name]: value });
    }
  };

  const validateListField = () => {
    let is_valid = true;
    logFormInput.problem_statement.forEach((item, index) => {
      Object.entries(item).forEach(([key, value]) => {
        if (!value) {
          is_valid = false;
          document
            .getElementById(`${key}-${index}`)
            ?.classList.add("is-invalid");
        }
      });
    });
    logFormInput.maintenance_activity.forEach((item, index) => {
      Object.entries(item).forEach(([key, value]) => {
        if (!value) {
          is_valid = false;
          document
            .getElementById(`${key}-${index}`)
            ?.classList.add("is-invalid");
        }
      });
    });
    logFormInput.comments.forEach((item, index) => {
      Object.entries(item).forEach(([key, value]) => {
        if (!value) {
          is_valid = false;
          document
            .getElementById(`${key}-${index}`)
            ?.classList.add("is-invalid");
        }
      });
    });
    return is_valid;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateListField()) {
      return false;
    }
    try {
      console.log("data", logFormInput);
      const resp = await updateLogBook(logFormInput);
      toast.success(resp.data.message);
      navigate("/log-book");
    } catch (error) {
      toast.error(error.response.data.message);
    }
  };

  const handleSelectChange = (name, value) => {
    setLogFormInput({ ...logFormInput, [name]: value });
  };

  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <div className="col-xl-12">
            <div className="card">
              <div className="card-header">
                <div className="d-flex ">
                  <button
                    type="button"
                    className="badge badge-circle badge-primary"
                    onClick={() => navigate(`/log-book-view/${data.id}`)}
                  >
                    <i className="fa-solid fa-arrow-left"></i>
                  </button>
                  <h4 className="mb-0 ms-2">Edit Log</h4>
                </div>
              </div>
              <div className="card-body">
                {data ? (
                  <Form onSubmit={(e) => handleSubmit(e)}>
                    <div className="row">
                      <div className="col-xl-5 col-sm-12 mx-auto">
                        <div className="mb-3">
                          <DynamicSelectInput
                            setParentInputValue={handleSelectChange}
                            endpoint_name="search/work_location"
                            name={"work_location_id"}
                            id={"work_location_id"}
                            label={"Work Location"}
                            defaultValue={{
                              value: data.work_location.id,
                              label: data.work_location.name,
                            }}
                            placeholder={"Select Work Location"}
                            isRequired={true}
                          />
                        </div>

                        <div className="mb-3">
                          <StaticSelectInput
                            optionsList={[
                              {
                                label: "General Shift",
                                value: "General Shift",
                              },
                              { label: "A Shift", value: "A Shift" },
                              { label: "B Shift", value: "B Shift" },
                              { label: "C Shift", value: "C Shift" },
                            ]}
                            setParentInputValue={handleSelectChange}
                            name={"shift_type"}
                            id={"shift_type"}
                            label={"Shift Type"}
                            defaultValue={{
                              label: data.shift_type,
                            }}
                            placeholder={"Select Shift Type"}
                            isRequired={true}
                          />
                        </div>

                        <div className="mb-3">
                          <StaticSelectInput
                            optionsList={[
                              {
                                label: "Break Down Maintenance",
                                value: "Break Down Maintenance",
                              },
                              {
                                label: "Corrective Maintenance",
                                value: "Corrective Maintenance",
                              },
                              {
                                label: "Preventive Maintenance",
                                value: "Preventive Maintenance",
                              },
                            ]}
                            setParentInputValue={handleSelectChange}
                            name={"classification"}
                            id={"classification"}
                            label={"Classification"}
                            defaultValue={{
                              label: data.classification,
                            }}
                            placeholder={"Select Classification"}
                            isRequired={true}
                          />
                        </div>

                        <div className="mb-3">
                          <label htmlFor="occurred_at" className="form-label">
                            Occurred At <span className="text-danger">*</span>
                          </label>
                          <input
                            className="form-control"
                            type="datetime-local"
                            id="occurred_at"
                            placeholder="Enter Comments"
                            name="occurred_at"
                            value={logFormInput.occurred_at}
                            onChange={handleChangeAdd}
                            required
                          />
                        </div>

                        <div className="mb-3">
                          <label
                            htmlFor="job_started_at"
                            className="form-label"
                          >
                            Job Started At{" "}
                            <span className="text-light">(Optional)</span>
                          </label>
                          <input
                            className="form-control"
                            type="datetime-local"
                            id="job_started_at"
                            placeholder=""
                            value={logFormInput.job_started_at}
                            name="job_started_at"
                            onChange={handleChangeAdd}
                          />
                        </div>

                        <div className="mb-3">
                          <label htmlFor="job_ended_at" className="form-label">
                            Job Ended At{" "}
                            <span className="text-light">(Optional)</span>
                          </label>
                          <input
                            className="form-control"
                            type="datetime-local"
                            id="job_ended_at"
                            placeholder=""
                            value={logFormInput.job_ended_at}
                            name="job_ended_at"
                            onChange={handleChangeAdd}
                          />
                        </div>
                      </div>
                      <div className="col-xl-5 col-sm-12 mx-auto">
                        <div className="mb-3">
                          <label>Job Type</label>
                          <br />
                          <div className="form-check custom-checkbox mb-2 form-check-inline">
                            <input
                              type="radio"
                              className="form-check-input"
                              checked={logFormInput.job_type === "Routine"}
                              id="routine"
                              name="job_type"
                              value={"Routine"}
                              onChange={handleChangeAdd}
                            />
                            <label
                              className="form-check-label"
                              htmlFor="routine"
                            >
                              Routine
                            </label>
                          </div>
                          <div className="form-check custom-checkbox mb-2 form-check-inline">
                            <input
                              type="radio"
                              className="form-check-input"
                              id="special"
                              name="job_type"
                              value={"Special"}
                              checked={logFormInput.job_type === "Special"}
                              onChange={handleChangeAdd}
                            />
                            <label
                              className="form-check-label"
                              htmlFor="special"
                            >
                              Special
                            </label>
                          </div>
                        </div>

                        {logFormInput.job_type === "Special" && (
                          <div className="mb-3">
                            <DynamicSelectInput
                              setParentInputValue={handleSelectChange}
                              endpoint_name="search/engineer"
                              name={"executed_by_id"}
                              id={"executed_by_id"}
                              label={"Executed By"}
                              defaultValue={
                                data.executed_by
                                  ? {
                                      value: data.executed_by.id,
                                      label: data.executed_by.name,
                                    }
                                  : null
                              }
                              placeholder={"Select Executed By"}
                              isRequired={true}
                            />
                          </div>
                        )}

                        <div className="mb-3">
                          <DynamicSelectInput
                            setParentInputValue={handleSelectChange}
                            endpoint_name="search/material"
                            name={"material_consumed_list"}
                            id={"material_consumed_list"}
                            label={"Meterail / Spare Consumed"}
                            is_multi={true}
                            defaultValue={data.material.map((item) => ({
                              value: item.id,
                              label: item.name,
                            }))}
                            placeholder={"Select Material / Spare"}
                            isRequired={false}
                          />
                        </div>

                        <div className="mb-3">
                          <label
                            htmlFor="problem_statement"
                            className="form-label"
                          >
                            Problem Statement{" "}
                            <span className="text-danger">*</span>
                          </label>
                          {logFormInput.problem_statement.map((item, index) => (
                            <div
                              className="d-flex align-items-center mb-3 "
                              key={index}
                            >
                              <div className="flex-grow-1 d-flex flex-column flex-sm-row">
                                <textarea
                                  className="form-control me-2"
                                  id={`summary-${index}`}
                                  placeholder="Enter Summary"
                                  value={item.summary}
                                  name="summary"
                                  onChange={(e) =>
                                    handleChange(
                                      index,
                                      "problem_statement",
                                      "summary",
                                      e.target.value
                                    )
                                  }
                                ></textarea>
                                <textarea
                                  className="form-control me-2 mt-2 mt-sm-0"
                                  id={`description-${index}`}
                                  placeholder="Enter Description"
                                  value={item.description}
                                  name="description"
                                  onChange={(e) =>
                                    handleChange(
                                      index,
                                      "problem_statement",
                                      "description",
                                      e.target.value
                                    )
                                  }
                                ></textarea>
                              </div>

                              <div className="m-2">
                                <button
                                  type="button"
                                  className="btn btn-sm btn-primary"
                                  onClick={() => addProblemStatement(index)}
                                >
                                  <i className="fa-solid fa-plus"></i>
                                </button>
                                {logFormInput["problem_statement"].length >
                                  1 && (
                                  <button
                                    type="button"
                                    className="btn btn-danger ms-2 btn-sm "
                                    onClick={() =>
                                      removeTextFiled(
                                        index,
                                        "problem_statement"
                                      )
                                    }
                                  >
                                    <i className="fa-solid fa-xmark"></i>
                                  </button>
                                )}
                              </div>
                            </div>
                          ))}
                        </div>
                        <div className="mb-3">
                          <label
                            htmlFor="maintenance_activity"
                            className="form-label"
                          >
                            Maintenance Activity
                            <span className="text-danger">*</span>
                          </label>
                          {logFormInput.maintenance_activity.map(
                            (item, index) => (
                              <div
                                className="d-flex align-items-center mb-3 "
                                key={index}
                              >
                                <div className="flex-grow-1">
                                  <input
                                    className="form-control"
                                    id={`activity-${index}`}
                                    placeholder="Enter Maintenance Activity"
                                    value={item.activity}
                                    name="activity"
                                    onChange={(e) =>
                                      handleChange(
                                        index,
                                        "maintenance_activity",
                                        "activity",
                                        e.target.value
                                      )
                                    }
                                  ></input>
                                </div>
                                <div className="m-2">
                                  <button
                                    type="button"
                                    className="btn btn-sm btn-primary"
                                    onClick={() =>
                                      addCommentMaintenance(
                                        index,
                                        "maintenance_activity",
                                        "activity"
                                      )
                                    }
                                  >
                                    <i className="fa-solid fa-plus"></i>
                                  </button>
                                  {logFormInput["problem_statement"].length >
                                    1 && (
                                    <button
                                      type="button"
                                      className="btn btn-danger ms-2 btn-sm "
                                      onClick={() =>
                                        removeTextFiled(
                                          index,
                                          "problem_statement"
                                        )
                                      }
                                    >
                                      <i className="fa-solid fa-xmark"></i>
                                    </button>
                                  )}
                                </div>
                              </div>
                            )
                          )}
                        </div>
                        <div className="mb-3">
                          <label htmlFor="textField" className="form-label">
                            Comments <span className="text-danger">*</span>
                          </label>
                          {logFormInput.comments.map((item, index) => (
                            <div
                              className="d-flex align-items-center mb-3 "
                              key={index}
                            >
                              <div className="flex-grow-1">
                                <textarea
                                  className="form-control"
                                  id={`text-${index}`}
                                  placeholder="Enter textField"
                                  value={item.text}
                                  name="text"
                                  onChange={(e) =>
                                    handleChange(
                                      index,
                                      "comments",
                                      "text",
                                      e.target.value
                                    )
                                  }
                                ></textarea>
                              </div>
                              <div className="m-2">
                                <button
                                  type="button"
                                  className="btn btn-sm btn-primary"
                                  onClick={() =>
                                    addCommentMaintenance(
                                      index,
                                      "comments",
                                      "text"
                                    )
                                  }
                                >
                                  <i className="fa-solid fa-plus"></i>
                                </button>
                                {logFormInput["problem_statement"].length >
                                  1 && (
                                  <button
                                    type="button"
                                    className="btn btn-danger ms-2 btn-sm "
                                    onClick={() =>
                                      removeTextFiled(
                                        index,
                                        "problem_statement"
                                      )
                                    }
                                  >
                                    <i className="fa-solid fa-xmark"></i>
                                  </button>
                                )}
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                    <div className="d-flex align-items-center justify-content-center mt-2">
                      <button type="submit" className="btn btn-primary me-1">
                        Update
                      </button>
                      <button
                        type="button"
                        className="btn btn-secondary light ms-4"
                        onClick={() => navigate("/log-book")}
                      >
                        Cancel
                      </button>
                    </div>
                  </Form>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EditLogBook;
