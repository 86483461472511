import { login, logoutApi, whoami } from "../../services/AuthService";

export const AUTH_CHECK_CONFIRMED_ACTION = "[Auth Action] auth ckeck confirmed";
export const LOGIN_CONFIRMED_ACTION = "[Auth Action] confirmed login";
export const LOGIN_FAILED_ACTION = "[Auth Action] failed login";
export const LOADING_TOGGLE_ACTION = "[Auth Action] toggle loading";
export const LOGOUT_ACTION = "[Auth Action] logout action";
export const UPDATE_PROFILE = "UPDATE PROFILE";
export const UPDATE_MOBILE_SCREEN = "UPDATE MOBILE SCREEN";
export const NAVTOGGLE = "NAVTOGGLE";

export function checkAutoLoginAction(navigate) {
  return (dispatch, getState) => {
    const user = getState().auth.user;
    if (!user) {
      dispatch(authCheckConfirmAction(true));
      whoami()
        .then((response) => {
          const { data } = response.data;
          dispatch(loginConfirmedAction(data));
        })
        .catch((error) => {
          console.log(error);
          dispatch(Logout(navigate));
          navigate("/"); //login
        });
    }
  };
}

export function Logout(navigate) {
  function dispatchCallBack() {
    return { type: LOGOUT_ACTION };
  }
  return (dispatch) => {
    logoutApi()
      .then((response) => {
        dispatch(dispatchCallBack());
        navigate("/"); //login
      })
      .catch((error) => {
        console.log(error);
      });
  };
}

export function loginAction(personnel_number, password, navigate) {
  return (dispatch) => {
    login(personnel_number, password)
      .then((response) => {
        const { data } = response.data;
        //saveTokenInLocalStorage({ access_token, refresh_token, data });
        dispatch(loginConfirmedAction({ ...data }));
        navigate("/dashboard");
      })
      .catch((error) => {
        dispatch(loginFailedAction(error.response.data.message));
      });
  };
}

export function loginFailedAction(data) {
  return {
    type: LOGIN_FAILED_ACTION,
    payload: data,
  };
}

export function loginConfirmedAction(data) {
  return {
    type: LOGIN_CONFIRMED_ACTION,
    payload: data,
  };
}

export function authCheckConfirmAction(payload) {
  return {
    type: AUTH_CHECK_CONFIRMED_ACTION,
    payload,
  };
}

export function loadingToggleAction(status) {
  return {
    type: LOADING_TOGGLE_ACTION,
    payload: status,
  };
}

export function updateProfileAction(data) {
  return {
    type: UPDATE_PROFILE,
    payload: data,
  };
}

export function updateMobileScreenAction(data) {
  return {
    type: UPDATE_MOBILE_SCREEN,
    payload: data,
  };
}

export const navtoggle = () => {
  return {
    type: "NAVTOGGLE",
  };
};
