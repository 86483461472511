import React, { useState } from "react";
import { useNavigate } from "react-router";
import { SVGICON } from "../../constant/theme";
import { Form, Offcanvas } from "react-bootstrap";
import { updateProfile } from "../../../services/ProductService";
import { updateProfileAction } from "../../../store/actions/AuthActions";
import { useDispatch } from "react-redux";
import LogoutPage from "./Logout";
import { connect } from "react-redux";
import { toast } from "react-toastify";

function BottomNav({ user }) {
  const navigate = useNavigate();
  const [empForm, setEmpForm] = useState({ name: "", password: "" });
  const [menuActive, setMenuActive] = useState("/dashboard");
  const [profileModalOpen, setProfileModalOpen] = useState(false);
  const dispatch = useDispatch();

  const handleOnClick = (url) => {
    navigate(url);
    setMenuActive(url);
  };

  const toggleProfileModal = () => {
    setEmpForm({ name: user?.name, password: "" });
    setProfileModalOpen(!profileModalOpen);
  };

  const handleChangeAdd = (e) => {
    const { name, value } = e.target;
    setEmpForm({ ...empForm, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    updateProfile(empForm)
      .then((response) => {
        dispatch(updateProfileAction(response.data.data));
        toast.success(response.data.message);
      })
      .catch((error) => {
        toast.error(error.response.data.message);
      });
  };

  return (
    <>
      <div className="bottom-nav">
        <nav role="navigation">
          <div className={menuActive === "/dashboard" ? "menu active" : "menu"}>
            <span onClick={() => handleOnClick("/dashboard")}>
              {SVGICON.MenuDashboard}
            </span>
          </div>
          <div
            className={
              menuActive === "/inspection-log" ? "menu active" : "menu"
            }
          >
            <span onClick={() => handleOnClick("/inspection-log")}>
              {SVGICON.MenuInspection}
            </span>
          </div>
          <div className={menuActive === "/shift-log" ? "menu active" : "menu"}>
            <span onClick={() => handleOnClick("/shift-log")}>
              {SVGICON.MenuInspectionLog}
            </span>
          </div>

          <div
            className={menuActive === "/user" ? "menu active" : "menu"}
            onClick={toggleProfileModal}
          >
            <span>{SVGICON.User}</span>
          </div>
        </nav>
      </div>

      <Offcanvas
        show={profileModalOpen}
        onHide={() => toggleProfileModal()}
        className="offcanvas-end"
        placement="end"
      >
        <div className="offcanvas-header">
          <h5 className="modal-title mx-3" id="#gridSystemModal">
            Profile
          </h5>
          <button
            type="button"
            className="btn-close mx-3"
            onClick={() => toggleProfileModal()}
          ></button>
        </div>
        <div className="offcanvas-body">
          <div className="container">
            <div className="card card-body mb-3">
              <h5>Profile Details</h5>
              <ul>
                <li className="my-2">
                  <strong>Name</strong>: {user?.name}
                </li>
                <li className="my-2">
                  <strong>Email</strong>: {user?.email}
                </li>
                <li className="my-2">
                  <strong>Role</strong>: {user?.role}
                </li>
                <li className="my-2">
                  <strong>Status</strong>: {user?.status}
                </li>
                {user?.role !== "Admin" ? (
                  <>
                    <li className="my-2">
                      <strong>Phone</strong>: {user?.mobile_number}
                    </li>
                    <li className="my-2">
                      <strong>Employee ID</strong>: {user?.employee_id}
                    </li>
                    <li className="my-2">
                      <strong>Designation</strong>: {user?.designation?.name}
                    </li>
                  </>
                ) : null}
              </ul>
            </div>

            <Form onSubmit={(e) => handleSubmit(e)} className="card card-body">
              <div className="row">
                <h5>Update Profile</h5>
                <div className="col-xl-12">
                  <div className="mb-3">
                    <label htmlFor="name" className="form-label">
                      Name <span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      autoFocus
                      className="form-control"
                      id="name"
                      placeholder="Enter Name"
                      value={empForm.name}
                      name="name"
                      onChange={handleChangeAdd}
                      required
                    />
                  </div>

                  <div className="mb-3">
                    <label htmlFor="password" className="form-label">
                      New Password <span>(Optional)</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="password"
                      placeholder="Enter New Password"
                      value={empForm.password}
                      name="password"
                      onChange={handleChangeAdd}
                    />
                  </div>
                </div>
              </div>
              <div>
                <button type="submit" className="btn btn-sm btn-primary me-1">
                  Submit
                </button>
                <button
                  type="button"
                  onClick={() => toggleProfileModal()}
                  className="btn btn-sm btn-danger light ms-1"
                >
                  Close
                </button>
              </div>
            </Form>
          </div>
          <LogoutPage isMobileScreen={true} />
        </div>
      </Offcanvas>
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
  };
};

export default connect(mapStateToProps)(BottomNav);
