import React, { useState, useEffect } from "react";
import { Form, Modal } from "react-bootstrap";
import { CancelRequest } from "../../../services/AxiosInstance";
import Pagination from "../../constant/Pagination";
import {
  getWorkLocationList,
  createWorkLocation,
  updateWorkLocation,
} from "../../../services/WorkLocationService";

import { toast } from "react-toastify";
import { LoaderEffect } from "../../constant/TableLoader";

function WorkLocationPage() {
  const [currentPage, setCurrentPage] = useState(1);
  const [searchQuery, setSearchQuery] = useState("");
  const [isModalOpen, setIsModalOpen] = useState({ open: false, type: "Add" });
  const [workLocationInput, setWorkLocationInput] = useState({ name: "" });
  const [data, setData] = useState([]);
  const [total, setTotal] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const recordsPage = 10;

  useEffect(() => {
    if (searchQuery.length > 0) {
      CancelRequest();
    } else {
      setIsLoading(true);
    }
    getWorkLocationList(currentPage, recordsPage, searchQuery)
      .then((resp) => {
        setData(resp.data.data);
        setTotal(resp.data.total);
        setIsLoading(false);
      })
      .catch((error) => {
        if (error.response) {
          console.log(error.response.data.message);
          setIsLoading(false);
        } else {
          console.log(error);
        }
      });
  }, [currentPage, searchQuery]);

  const toggleModal = (type = "Add", areaObj = null) => {
    let open = isModalOpen.open;

    if (type === "Add") {
      open = !open;
    } else if (type === "Edit" && areaObj) {
      setWorkLocationInput({
        name: areaObj.name,
        id: areaObj.id,
      });
      open = true;
    }
    if (isModalOpen.open && areaObj === null) {
      setWorkLocationInput({
        name: "",
      });
    }
    setIsModalOpen({ type, open });
  };

  const handleSearchChange = (e) => {
    const { value } = e.target;
    setSearchQuery(value);
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    if (!workLocationInput.name.trim()) {
      document.getElementById("name").classList.add("is-invalid");
      return false;
    }

    if (!workLocationInput.name.trim()) {
      document.getElementById("name").classList.add("is-invalid");
      return false;
    }

    if (isModalOpen.type === "Add") {
      try {
        const resp = await createWorkLocation(workLocationInput);
        const resp_data = resp.data.data;
        setData([resp_data, ...data]);
        setTotal(total + 1);
        toast.success(resp.data.message);
        toggleModal();
      } catch (error) {
        toast.error(error.response.data.message);
      }
    } else {
      try {
        const response = await updateWorkLocation(workLocationInput);
        const resp_data = response.data;
        setData(
          data.map((user) =>
            user.id === resp_data.data.id ? { ...resp_data.data } : user
          )
        );
        toast.success(resp_data.message);
        toggleModal();
      } catch (error) {
        console.log(error);
        if (error && error.response) {
          toast.error(error.response.data.message);
        }
      }
    }
  };

  const handlePagination = (page) => {
    setIsLoading(true);
    const new_page = page.selected + 1;
    setCurrentPage(new_page);
  };

  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <div className="col-xl-12">
            <div className="card">
              <div className="card-body p-0">
                <div className="table-responsive active-projects style-1 ItemsCheckboxSec shorting">
                  <div className="tbl-caption d-flex justify-content-between text-wrap align-items-center">
                    <h4 className="heading mb-0">Work Location</h4>
                    <div className="d-flex">
                      <div className="form-group me-5">
                        <input
                          type="search"
                          name="serach"
                          className="form-control "
                          placeholder="Search"
                          value={searchQuery}
                          onChange={handleSearchChange}
                        />
                      </div>
                      <button
                        className="btn btn-primary btn-sm ms-1"
                        data-bs-toggle="offcanvas"
                        onClick={() => toggleModal()}
                      >
                        + Add Work Location
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-12 py-md-3">
          <div className="row">
            {isLoading ? (
              <LoaderEffect />
            ) : (
              data.map((item) => {
                return (
                  <div className="col-lg-3 col-md-4 col-sm-6" key={item.id}>
                    <div className="card">
                      <div className="card-body d-flex justify-content-between align-items-center ">
                        <h5 className="card-title ">{item.name}</h5>
                        <button
                          type="button"
                          className="btn btn-primary tp-btn-light btn-icon-xs"
                          onClick={() => toggleModal("Edit", item)}
                        >
                          <i className="fa-solid fa-pen-to-square"></i>
                        </button>
                      </div>
                    </div>
                  </div>
                );
              })
            )}
          </div>
        </div>
        {isLoading ? (
          <div style={{ height: "4rem" }}></div>
        ) : (
          <Pagination
            currentPage={currentPage}
            recordsPage={recordsPage}
            dataLength={total}
            handlePagination={handlePagination}
          />
        )}
      </div>

      <Modal
        className="modal modal-sm fade"
        id="exampleModal1"
        show={isModalOpen.open}
        onHide={() => toggleModal()}
      >
        <div className="modal-content">
          <div className="modal-header">
            <h1 className="modal-title fs-5" id="exampleModalLabel1">
              {isModalOpen.type === "Add" ? "Create " : "Edit "}
              Work Location
            </h1>
            <button
              type="button"
              className="btn-close"
              onClick={() => toggleModal()}
            ></button>
          </div>
          <div className="modal-body">
            <Form onSubmit={onSubmit}>
              <div className="row">
                <div className="col-xl-12">
                  <label className="form-label" htmlFor="name">
                    Work Location :<span className="text-danger">*</span>
                  </label>
                  <input
                    type="text"
                    className="form-control mb-3"
                    placeholder="Enter Work Location"
                    name="name"
                    id="name"
                    autoFocus
                    value={workLocationInput.name}
                    onChange={(e) => {
                      setWorkLocationInput({
                        ...workLocationInput,
                        name: e.target.value,
                      });
                      if (e.target.value) {
                        e.target.classList.remove("is-invalid");
                      }
                    }}
                  />
                </div>
              </div>
              <div>
                <button type="submit" className="btn btn-primary me-1">
                {isModalOpen.type === "Add" ? "Create" : "Update"}
                </button>
                <button
                  type="button"
                  onClick={() => toggleModal()}
                  className="btn btn-danger light ms-1"
                >
                  Cancel
                </button>
              </div>
            </Form>
          </div>
        </div>
      </Modal>
    </>
  );
}

export default WorkLocationPage;
