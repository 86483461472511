// ** React Imports
import { Fragment, useEffect } from 'react'
import { useParams, useNavigate } from "react-router-dom"
// ** Third Party Components

// ** Reactstrap Imports
import { Tab, Nav, NavItem, NavLink } from 'react-bootstrap'
import CheckListTab from './CheckListTab'
import PlannedJobTab from './PlannedJobTab'
import ScheduleTab from './ScheduleTab'

const PlanedJobPages = () => {
    // ** States
    const DEFAULT_ACTIVE_TAB = "job"
    const { active_tab } = useParams()
    const navigate = useNavigate()

    useEffect(() => {
        if (!active_tab) {
            navigate(`/planned-job/${DEFAULT_ACTIVE_TAB}`)
        } else {
            navigate(`/planned-job/${active_tab}`)
        }
    }, [active_tab])

    const toggleTab = tab => {
        if (active_tab !== tab) {
            navigate(`/planned-job/${tab}`)
        }
    }

    const tabList = {
        job: {
            title: "Job",
            content: <PlannedJobTab />
        },
        schedule: {
            title: "Schedule",
            content: <ScheduleTab />
        },
        check_list: {
            title: "Check List",
            content: <CheckListTab />
        },
    }

    return (
        <div className="container-fluid">

            <Tab.Container id="left-tabs-example" activeKey={active_tab}>
                <div className='card p-0 my-3 m-0'>
                    <Nav variant="pills" className='py-3 ms-2'>
                        {
                            Object.entries(tabList).map((item) => (
                                <NavItem key={item[0]}>
                                    <NavLink eventKey={item[0]} onClick={() => { toggleTab(item[0]) }} role="button">
                                        {/* {item[1].icon} */}
                                        <span className='fw-bold'>{item[1].title}</span>
                                    </NavLink>
                                </NavItem>
                            ))
                        }
                    </Nav>
                </div>
                <Tab.Content>
                    {
                        Object.entries(tabList).map((item) => (
                            <Tab.Pane key={item[0]} eventKey={item[0]}>
                                {item[1].content}
                            </Tab.Pane>
                        ))
                    }
                </Tab.Content>
            </Tab.Container>
        </div>
    )
}

export default PlanedJobPages
