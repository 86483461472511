export const CREATE_RACK_ACTION = '[Rack Action] Create Rack';
export const CONFIRMED_CREATE_RACK_ACTION = '[Rack Action] Confirmed Create Rack';
export const GET_RACK = '[Rack Action] Get RACK';
export const CONFIRMED_GET_RACK = '[Rack Action] Confirmed Get Rack';
export const GET_RACK_LIST = '[Rack Action] Get Rack List';
export const CONFIRMED_GET_RACK_LIST = '[Rack Action] Confirmed Get Rack List';
export const CONFIRMED_GET_RACK_PRODUCTS = '[Rack Action] Confirmed Get Rack Products';
export const EDIT_RACK_ACTION = '[Rack Action] Edit Rack';
export const CONFIRMED_EDIT_RACK_ACTION = '[Rack Action] Confirmed Edit Rack';
export const CONFIRMED_DELETE_RACK_ACTION = '[Rack Action] Confirmed Delete Rack';
export const HANDLE_SELETED_RACK = '[Rack Action] Handle Selected Rack';
export const HANDLE_SELETED_VIEW_STOCK = '[Rack Action] Handle Selected View Stock';
export const LOADING_RACK_ACTION = '[Rack Action] Toggle Loading';
export const LOADING_SINGLE_RACK_ACTION = '[Rack Action] Toggle Loading Single Rack';
export const MODAL_TOGGLE_ACTION = '[Rack Action] Toggle Modal';
export const SHOW_TOAST_ERROR = '[Rack Action] Show Error';
