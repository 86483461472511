import React, { useState, useEffect } from "react";
import ReactApexChart from "react-apexcharts";
import DataLoader from "../Loader";

const ShiftLogShiftColumnCard = ({ data , isLoading}) => {
  const [label, setLabel] = useState([])
  const [series, setSeries] = useState([])

  useEffect(() => {
    if (data.length) {
      setLabel(data.map(item => item.shift))

      const format_seres = data[0].classification.map(({ name }) => {
        const counts = data.map(({ classification }) =>
        classification.find((job) => job.name === name).count
        );
        return { name, data: counts };
      });
      setSeries(format_seres)
    }
  }, [data])

  const options = {
    chart: {
      type: 'bar',
      height: 300,
      stacked: true,
      toolbar: {
        show: false
      },
      zoom: {
        enabled: true
      }
    },
    responsive: [
      {
        breakpoint: 480,
        options: {
          legend: {
            position: 'bottom',
            offsetX: -10,
            offsetY: 0
          }
        }
      }
    ],
    plotOptions: {
      bar: {
        horizontal: false,
        borderRadius: 10,
        dataLabels: {
          total: {
            enabled: true,
            style: {
              fontSize: '13px',
              fontWeight: 900
            }
          }
        }
      },

    },
    xaxis: {
      type: 'string',
      categories: label,

    },
    legend: {
      position: 'bottom',
      offsetY: 5
    },
    fill: {
      opacity: 1
    }
  }

  return (
    <div className="card" >
      <div className="card-header border-0 pb-0 flex-wrap">
        <h4 className="heading mb-0">Shift Overview</h4>

      </div>
      <div className="card-body  p-0">
        {!isLoading ?
          <div id="shiftcolumnChart">
            <ReactApexChart
              options={options}
              series={series}
              type="bar"
              height={300}
            />
          </div>
          : <DataLoader />
        }
      </div>
    </div>
  );

}

export default ShiftLogShiftColumnCard;

