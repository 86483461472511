import React from "react";
import { connect } from "react-redux";
import ChangePassword from "./ChangePassword";

const ProfilePage = ({ user }) => {
  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <div className="col-sm-3"></div>
          <div className="col-sm-6">
            <div className="card">
              <div className="card-header p-2">
                <h4 className="ps-2">Profile</h4>
              </div>
              <div className="card-body">
                <div className="d-flex flex-column">
                  <div className="my-2 d-flex">
                    <strong>Name:</strong>
                    <span className="ms-2">{user.name}</span>
                  </div>

                  <div className="my-2 d-flex">
                    <strong>Personnel Number:</strong>
                    <span className="ms-2">{user.personnel_number}</span>
                  </div>

                  <div className="my-2 d-flex">
                    <strong>Role:</strong>
                    <span className="ms-2">{user.role}</span>
                  </div>

                  <div className="my-2 d-flex">
                    <strong>Status:</strong>
                    <span className="ms-2">{user.status}</span>
                  </div>

                  <div className="my-3">
                    <ChangePassword />
                  </div>
                </div>
              </div>
            </div>


          </div>
          <div className="col-sm-3"></div>
        </div>

        {/* <Modal
          className="modal fade modal-sm"
          id="exampleModal1"
          show={showModal}
          centered={true}
          onHide={() => toggleModal(false)}
        >
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="modal-title fs-5" id="exampleModalLabel1">
                Change User Password
              </h1>
              <button
                type="button"
                className="btn-close"
                onClick={() => toggleModal(false)}
              ></button>
            </div>
            <div className="modal-body">
              <Form onSubmit={handleSubmit}>
                <div className="row">
                  <div className="mb-3">
                    <label htmlFor="current_password" className="form-label">
                      Current Password <span className="text-danger">*</span>
                    </label>
                    <div className="input-group ">
                      <input
                        type={showPassword.cPass ? "text" : "password"}
                        className="form-control border border-end-0"
                        id="current_password"
                        placeholder="Enter Current Password"
                        name="current_password"
                        autoComplete="new-password"
                        value={values.current_password}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      <button
                        className="btn border border-start-0 border-light-subtle pb-2 "
                        type="button"
                        onClick={() => handleTogglePassword("cPass")}
                      >
                        {showPassword.cPass ? (
                          <i className="fa-regular fa-eye-slash"></i>
                        ) : (
                          <i className="fa-regular fa-eye"></i>
                        )}
                      </button>
                    </div>
                    {errors.current_password && touched.current_password ? (
                      <div className="text-danger small mt-1">
                        {errors.current_password}
                      </div>
                    ) : null}
                  </div>
                  <div className="mb-3">
                    <label htmlFor="new_password" className="form-label">
                      New Password <span className="text-danger">*</span>
                    </label>
                    <div className="input-group ">
                      <input
                        type={showPassword.newPass ? "text" : "password"}
                        className="form-control border border-end-0"
                        id="new_password"
                        placeholder="Enter New Password"
                        name="new_password"
                        autoComplete="new-password"
                        value={values.new_password}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      <button
                        className="btn border border-start-0 border-light-subtle pb-2 "
                        type="button"
                        onClick={() => handleTogglePassword("newPass")}
                      >
                        {showPassword.newPass ? (
                          <i className="fa-regular fa-eye-slash"></i>
                        ) : (
                          <i className="fa-regular fa-eye"></i>
                        )}
                      </button>
                    </div>
                    {errors.new_password && touched.new_password ? (
                      <div className="text-danger small mt-1">
                        {errors.new_password}
                      </div>
                    ) : null}
                  </div>
                  <div className="mb-3">
                    <label htmlFor="confirmPassword" className="form-label">
                      Confirm New Password <span className="text-danger">*</span>
                    </label>
                    <div className="input-group ">
                      <input
                        type={showPassword.newCPass ? "text" : "password"}
                        className="form-control border border-end-0"
                        id="confirmPassword"
                        placeholder="Enter Confirm New Password"
                        name="confirmPassword"
                        autoComplete="new-password"
                        value={values.confirmPassword}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      <button
                        className="btn border border-start-0 border-light-subtle pb-2 "
                        type="button"
                        onClick={() => handleTogglePassword("newCPass")}
                      >
                        {showPassword.newCPass ? (
                          <i className="fa-regular fa-eye-slash"></i>
                        ) : (
                          <i className="fa-regular fa-eye"></i>
                        )}
                      </button>
                    </div>
                    {errors.confirmPassword && touched.confirmPassword ? (
                      <div className="text-danger small mt-1">
                        {errors.confirmPassword}
                      </div>
                    ) : null}
                  </div>
                </div>
                <button
                  type="submit"
                  className="btn btn-block btn-sm btn-primary mt-3 float-end"
                >
                  Update Password
                </button>
              </Form>
            </div>
          </div>
        </Modal> */}
      </div>
    </>
  );
};
const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
  };
};

export default connect(mapStateToProps)(ProfilePage);

