import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { connect } from "react-redux";
import Filter from "./UnPlannedLogBook/Filter";
import PlannedLogBookPage from "./PlannedLogBook/plannedLogBook";
import UnPlannedLogBookPage from "./UnPlannedLogBook/unPlannedLogBook";
import PlannedLogFilter from "./PlannedLogBook/Filter";
import ExcelLogBook from "./excelLogBook";

const LogBookListPage = ({ isMobileScreen, role }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const paramName = searchParams.get("state");
  const [searchQuery, setSearchQuery] = useState("");
  const [active, setActive] = useState(paramName ? false : true);
  const [advanceFilterInput, setAdvanceFilterInput] = useState({
    job_type: "",
    classification: "",
    shift_type: "",
    status: "",
    start_date: "",
    end_date: "",
  });
  const [advanceFilterPlannedLog, setAdvanceFilterPlannedLog] = useState({
    work_location: "",
    shift_type: "",
    planned_job: "",
    status: "",
    start_date: "",
    end_date: "",
  });

  useEffect(() => {
    if (paramName) {
      navigate(location.pathname);
    }
  }, []);

  const handleButtonClick = (isActive) => {
    setActive(isActive);
  };

  const handleFilterChange = (filterInput) => {
    setAdvanceFilterInput(filterInput);
  };
  const handleFilterChangePlanned = (filterInput) => {
    setAdvanceFilterPlannedLog(filterInput);
  };

  const handleSearchChange = (e) => {
    const { value } = e.target;
    setSearchQuery(value);
  };

  return (
    <div className="container-fluid">
      {isMobileScreen ? (
        <div>
          <div class="p-1 bg-white rounded rounded-pill shadow-sm mb-2 p-2">
            <div>
              <button
                className={` ${
                  active ? "badge badge-primary" : "badge light badge-primary"
                } me-2`}
                onClick={() => handleButtonClick(true)}
              >
                Unplanned Log
              </button>
              <button
                className={` ${
                  !active ? "badge badge-primary" : "badge light badge-primary"
                }`}
                onClick={() => handleButtonClick(false)}
              >
                Planned Log
              </button>{" "}
            </div>
            <div className="d-flex">
              <div class="input-group filter-search mt-3">
                <input
                  type="text"
                  class="form-control"
                  placeholder="Search..."
                  value={searchQuery}
                  onChange={handleSearchChange}
                />
                <span class="input-group-text bg-transparent">
                  {active ? (
                    <Filter onApplyChanges={handleFilterChange} />
                  ) : (
                    <PlannedLogFilter
                      jobType={advanceFilterPlannedLog}
                      onApplyChanges={handleFilterChangePlanned}
                    />
                  )}
                </span>
              </div>
              {active && role !== "Employee" && (
                <button
                  className="btn btn-primary btn-sm ms-2 w-50 p-0 mt-3"
                  onClick={() => navigate("/log-book-add")}
                >
                  Add Log
                </button>
              )}
            </div>
          </div>
          {active ? (
            <UnPlannedLogBookPage
              searchQuery2={searchQuery}
              filterData={advanceFilterInput}
            />
          ) : (
            <PlannedLogBookPage
              searchQuery2={searchQuery}
              filterData={advanceFilterPlannedLog}
            />
          )}
        </div>
      ) : (
        <div className="row">
          <div className="col-xl-12">
            <div className="card">
              <div className="card-body p-0">
                <div className="table-responsive active-projects style-1 ItemsCheckboxSec shorting">
                  <div className="tbl-caption d-flex justify-content-between text-wrap align-items-center">
                    <div>
                      <button
                        className={` ${
                          active
                            ? "badge badge-primary"
                            : "badge light badge-primary"
                        } me-2`}
                        onClick={() => handleButtonClick(true)}
                      >
                        Unplanned Log
                      </button>
                      <button
                        className={` ${
                          !active
                            ? "badge badge-primary"
                            : "badge light badge-primary"
                        }`}
                        onClick={() => handleButtonClick(false)}
                      >
                        Planned Log
                      </button>{" "}
                    </div>
                    <div className="d-flex">
                      <ExcelLogBook
                        logType={active}
                        filterData={
                          active ? advanceFilterInput : advanceFilterPlannedLog
                        }
                      />

                      <div class="input-group filter-search mx-2">
                        <input
                          type="text"
                          class="form-control"
                          placeholder="Search..."
                          value={searchQuery}
                          onChange={handleSearchChange}
                        />
                        <span class="input-group-text bg-transparent">
                          {active ? (
                            <Filter onApplyChanges={handleFilterChange} />
                          ) : (
                            <PlannedLogFilter
                              onApplyChanges={handleFilterChangePlanned}
                            />
                          )}
                        </span>
                      </div>
                      {active && role !== "Employee" && (
                        <button
                          className="btn btn-primary btn-sm w-50 p-0"
                          onClick={() => navigate("/log-book-add")}
                        >
                          Add Log
                        </button>
                      )}
                    </div>
                  </div>
                  {active ? (
                    <UnPlannedLogBookPage
                      searchQuery2={searchQuery}
                      filterData={advanceFilterInput}
                    />
                  ) : (
                    <PlannedLogBookPage
                      searchQuery2={searchQuery}
                      filterData={advanceFilterPlannedLog}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    role: state.auth.user?.role || "Employee",
    isMobileScreen: state.auth.isMobileScreen,
  };
};

export default connect(mapStateToProps)(LogBookListPage);
