import React, { useState, useEffect } from "react";
import Pagination from "../../../constant/Pagination";
import { connect } from "react-redux";
import { CancelRequest } from "../../../../services/AxiosInstance";
import { getLogBookList } from "../../../../services/LogBookService";
import TableLoaderEffect from "../../../constant/TableLoader";
import { useNavigate } from "react-router-dom";

const UnPlannedLogBookPage = ({ searchQuery2, filterData, isMobileScreen }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [data, setData] = useState([]);
  const [total, setTotal] = useState(0);
  const [isLoading, setIsLoading] = useState(false);

  const recordsPage = 10;
  const navigate = useNavigate();

  useEffect(() => {
    if (searchQuery2.length > 0) {
      CancelRequest();
    } else {
      setIsLoading(true);
    }
    getLogBookList(
      currentPage,
      recordsPage,
      searchQuery2,
      filterData.job_type,
      filterData.shift_type,
      filterData.classification,
      filterData.status,
      filterData.start_date,
      filterData.end_date
    )
      .then((resp) => {
        setData(resp.data.data);
        setTotal(resp.data.total);
        setIsLoading(false);
      })
      .catch((error) => {
        if (error.response) {
          console.log(error.response.data.message);
          setIsLoading(false);
        } else {
          console.log(error);
        }
      });
  }, [currentPage, searchQuery2, filterData]);

  const handlePagination = (active) => {
    setIsLoading(true);
    const new_page = active.selected + 1;
    setCurrentPage(new_page);
  };

  return (
    <>
      {isMobileScreen ? (
        <div className="row">
          {data.map((item, index) => (
            <div className="col-sm-6" key={index}>
              <div
                class="card"
                onClick={() => navigate(`/log-book-view/${item.id}`)}
              >
                <div class="card-body">
                  <p class="card-text">
                    <strong>Work Location: </strong>
                    <span className="text-primary cursor-pointer fw-bolder">
                      {item.work_location.name}
                    </span>
                  </p>
                  <p class="card-text">
                    <strong>Job Type: </strong>
                    {item.job_type === "Special" ? (
                      <span>
                        <div className="badge badge-outline-primary badge-sm badge-rounded me-2">
                          Special
                        </div>
                        <p class="card-text">
                          <strong>Executed By: </strong>
                          <span>{item.executed_by.name}</span>
                        </p>
                      </span>
                    ) : (
                      <span className="badge badge-outline-info badge-sm badge-rounded">
                        Routine
                      </span>
                    )}
                  </p>
                  <p class="card-text">
                    <strong>Shift Type: </strong>
                    <span>{item.shift_type}</span>
                  </p>
                  <p class="card-text">
                    <strong>Classification: </strong>
                    <span>{item.classification}</span>
                  </p>
                  <p class="card-text">
                    <strong>Occurred At: </strong>
                    <span>{item.occurred_at}</span>
                  </p>
                  <div className="my-2 d-flex justify-content-end">
                    {item.status === "Completed" ? (
                      <span className={`badge light border-0 badge-success`}>
                        Completed
                      </span>
                    ) : item.status === "Ongoing" ? (
                      <span className={`badge light border-0 badge-info`}>
                        Onging
                      </span>
                    ) : (
                      <span className={`badge light border-0 badge-warning`}>
                        Initiated
                      </span>
                    )}
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      ) : (
        <div id="employee-tbl_wrapper" className="dataTables_wrapper no-footer">
          <table
            id="empoloyees-tblwrapper"
            className="table ItemsCheckboxSec dataTable no-footer mb-0"
          >
            <thead>
              <tr>
                <th>Location</th>
                <th>Job Type</th>
                <th>Shift Type</th>
                <th>Classification</th>
                <th>Occurred At</th>
                <th>Status</th>
              </tr>
            </thead>
            <tbody>
              {isLoading ? (
                <TableLoaderEffect colLength={6} />
              ) : (
                data.map((item, index) => (
                  <tr key={index}>
                    <td>
                      <span
                        className="text-primary cursor-pointer fw-bolder"
                        onClick={() => navigate(`/log-book-view/${item.id}`)}
                      >
                        {item.work_location.name}
                      </span>
                    </td>
                    <td>
                      {item.job_type === "Special" ? (
                        <div className="d-flex flex-column">
                          <div>
                            <span className="badge badge-outline-primary badge-sm badge-rounded">
                              Special
                            </span>
                          </div>
                          <span>{item.executed_by.name}</span>
                        </div>
                      ) : (
                        <div>
                          <span className="badge badge-outline-info badge-sm badge-rounded">
                            Routine
                          </span>
                        </div>
                      )}
                    </td>
                    <td>
                      <span>{item.shift_type}</span>
                    </td>
                    <td>
                      <span>{item.classification}</span>
                    </td>
                    <td>
                      <span>{item.occurred_at}</span>
                    </td>
                    <td>
                      {item.status === "Completed" ? (
                        <span className={`badge light border-0 badge-success`}>
                          Completed
                        </span>
                      ) : item.status === "Ongoing" ? (
                        <span className={`badge light border-0 badge-info`}>
                          Onging
                        </span>
                      ) : (
                        <span className={`badge light border-0 badge-warning`}>
                          Initiated
                        </span>
                      )}
                    </td>
                  </tr>
                ))
              )}
            </tbody>
          </table>

          {isLoading ? (
            <div style={{ height: "4rem" }}></div>
          ) : (
            <Pagination
              currentPage={currentPage}
              recordsPage={recordsPage}
              dataLength={total}
              handlePagination={handlePagination}
            />
          )}
        </div>
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    isMobileScreen: state.auth.isMobileScreen,
  };
};

export default connect(mapStateToProps)(UnPlannedLogBookPage);
