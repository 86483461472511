import axiosInstance from './AxiosInstance';

export function dashboard() {
    return axiosInstance.get('api/dashboard');
}
export function activityDashboard() {
    return axiosInstance.get('api/activity_dashboard');
}
export function dashboardShiftLogOverview(period = "week") {
    return axiosInstance.get(`api/dashboard_unplannedjob_overview?period=${period}`);
}
export function dashboardInspectionLogOverview(period = "week") {
    return axiosInstance.get(`api/dashboard_inspectionlog_overview?period=${period}`);
}
