import React, { useState, useEffect } from "react";
import Pagination from "../../../constant/Pagination";
import { CancelRequest } from "../../../../services/AxiosInstance";
import { connect } from "react-redux";
import { getPlannedLogBookList } from "../../../../services/LogBookService";
import TableLoaderEffect from "../../../constant/TableLoader";
import { useNavigate } from "react-router-dom";

const PlannedLogBookPage = ({ searchQuery2, filterData, isMobileScreen }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [data, setData] = useState([]);
  const [total, setTotal] = useState(0);
  const [isLoading, setIsLoading] = useState(false);

  const recordsPage = 10;
  const navigate = useNavigate();

  useEffect(() => {
    if (searchQuery2.length > 0) {
      CancelRequest();
    } else {
      setIsLoading(true);
    }
    getPlannedLogBookList(
      currentPage,
      recordsPage,
      searchQuery2,
      filterData.work_location,
      filterData.shift_type,
      filterData.planned_job,
      filterData.status,
      filterData.start_date,
      filterData.end_date
    )
      .then((resp) => {
        setData(resp.data.data);
        setTotal(resp.data.total);
        setIsLoading(false);
      })
      .catch((error) => {
        if (error.response) {
          console.log(error.response.data.message);
          setIsLoading(false);
        } else {
          console.log(error);
        }
      });
  }, [currentPage, searchQuery2, filterData]);

  const handlePagination = (page) => {
    setIsLoading(true);
    const new_page = page.selected + 1;
    setCurrentPage(new_page);
  };

  const StatusButtonComponent = ({ item }) => {
    let statusbtn;

    switch (item.status) {
      case "Pending":
        statusbtn = (
          <span
            className={`badge light badge-warning`}
            data-bs-toggle="offcanvas"
          >
            Pending
          </span>
        );
        break;
      case "Completed":
        statusbtn = (
          <span
            className={`badge light border-0 badge-success`}
            data-bs-toggle="offcanvas"
          >
            Completed
          </span>
        );
        break;
      case "Defected":
        statusbtn = (
          <span
            className={`badge light border-0 badge-info`}
            data-bs-toggle="offcanvas"
          >
            Defected
          </span>
        );
        break;
      case "Overdue":
        statusbtn = (
          <span
            className={`badge light border-0 badge-danger`}
            data-bs-toggle="offcanvas"
          >
            Overdue
          </span>
        );
        break;
      default:
        statusbtn = (
          <span className={`badge light badge-light`} disabled>
            Upcoming
          </span>
        );
    }
    return statusbtn;
  };

  return (
    <>
      {isMobileScreen ? (
        <div className="row">
          {data.map((item, index) => (
            <div className="col-sm-6" key={index}>
              <div
                class="card"
                onClick={() => navigate(`/log-book-view/${item.id}`)}
              >
                <div class="card-body">
                  <p class="card-text">
                    <strong>Work Location: </strong>
                    <span className="text-primary cursor-pointer fw-bolder">
                      {item.work_location.name}
                    </span>
                  </p>
                  <p class="card-text">
                    <strong>Shift Type: </strong>
                    <span>{item.shift_type}</span>
                  </p>
                  <p class="card-text">
                    <strong>Planned Job: </strong>
                    <span>{item.planned_job.name}</span>
                  </p>
                  <p class="card-text">
                    <strong>Created At: </strong>
                    <span>{item.created_at}</span>
                  </p>
                  <div className="my-2 d-flex justify-content-end">
                    <StatusButtonComponent item={item} />
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      ) : (
        <div id="employee-tbl_wrapper" className="dataTables_wrapper no-footer">
          <table
            id="empoloyees-tblwrapper"
            className="table ItemsCheckboxSec dataTable no-footer mb-0"
          >
            <thead>
              <tr>
                <th>Location</th>
                <th>Shift Type</th>
                <th>Planned Job</th>
                <th>Created At</th>
                <th>Status</th>
              </tr>
            </thead>
            <tbody>
              {isLoading ? (
                <TableLoaderEffect colLength={6} />
              ) : (
                data.map((item, index) => (
                  <tr key={index}>
                    <td>
                      <span
                        className="text-primary cursor-pointer fw-bolder"
                        onClick={() => navigate(`/log-book-view/${item.id}`)}
                      >
                        {item.work_location.name}
                      </span>
                    </td>
                    <td>
                      <span>{item.shift_type}</span>
                    </td>
                    <td>
                      <span>{item.planned_job.name}</span>
                    </td>
                    <td>
                      <span>{item.created_at}</span>
                    </td>
                    <td>
                      <StatusButtonComponent item={item} />
                    </td>
                  </tr>
                ))
              )}
            </tbody>
          </table>

          {isLoading ? (
            <div style={{ height: "4rem" }}></div>
          ) : (
            <Pagination
              currentPage={currentPage}
              recordsPage={recordsPage}
              dataLength={total}
              handlePagination={handlePagination}
            />
          )}
        </div>
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    isMobileScreen: state.auth.isMobileScreen,
  };
};

export default connect(mapStateToProps)(PlannedLogBookPage);
