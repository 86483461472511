import React, { useState, useEffect } from "react";
import Pagination from "../../constant/Pagination";
import { toast } from "react-toastify";
import { Form, Modal } from "react-bootstrap";
import { CancelRequest } from "../../../services/AxiosInstance";
import { connect } from "react-redux";
import {
  getPlannedJobList,
  createPlannedJob,
} from "../../../services/PlannedJobService";
import DynamicSelectInput, {
  StaticSelectInput,
} from "../../constant/SelectInput";
import TableLoaderEffect from "../../constant/TableLoader";

const PlannedJobTab = ({ isMobileScreen }) => {
  const initialJobInput = {
    name: "",
    schedule_id: "",
    work_location_id: "",
    shift_type: "",
    checklist_id: [],
  };
  const initialJobInputError = {
    name: false,
    schedule_id: false,
    work_location_id: false,
    shift_type: false,
    checklist_id: false,
  };
  const [currentPage, setCurrentPage] = useState(1);
  const [searchQuery, setSearchQuery] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isViewModalOpen, setIsViewModalOpen] = useState(false);
  const [jobInput, SetJobInput] = useState(initialJobInput);
  const [jobInputError, SetJobInputError] = useState(initialJobInputError);
  const [data, setData] = useState([]);
  const [total, setTotal] = useState(0);
  const [isSearchLoading, setIsSearchLoading] = useState(false);
  const [isSubmitLoading, setIsSubmitLoading] = useState(false);
  const [selectedJob, setSelectedJob] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const recordsPage = 10;

  useEffect(() => {
    if (searchQuery.length > 0) {
      CancelRequest();
    } else {
      setIsLoading(true);
    }
    getPlannedJobList(currentPage, recordsPage, searchQuery)
      .then((resp) => {
        setData(resp.data.data);
        setTotal(resp.data.total);
        setIsLoading(false);
        setIsSearchLoading(false);
      })
      .catch((error) => {
        if (error.response) {
          console.log(error.response.data.message);
          setIsLoading(false);
          setIsSearchLoading(false);
        } else {
          console.log(error);
        }
      });
  }, [currentPage, searchQuery]);

  const toggleViewModal = () => {
    if (isViewModalOpen) {
      setSelectedJob(null);
    }
    setIsViewModalOpen(!isViewModalOpen);
  };

  const toggleModal = () => {
    if (isModalOpen) {
      SetJobInput(initialJobInput);
      SetJobInputError(initialJobInputError);
    }
    setIsModalOpen(!isModalOpen);
  };

  const handleSearchChange = (e) => {
    const { value } = e.target;
    setSearchQuery(value);
    setIsSearchLoading(true);
    setCurrentPage(1);
  };

  const handlePagination = (page) => {
    setIsLoading(true);
    const new_page = page.selected + 1;
    setCurrentPage(new_page);
  };

  const handleChangeAdd = (e) => {
    const { name, value } = e.target;
    if (name === "schedule_type") {
      try {
        if (value === "Weekly") {
          const {
            day_of_month: ommitday_of_month,
            month_of_year: ommitmonth_of_year,
            ...newInspectionInput
          } = jobInput;
          SetJobInput({
            ...newInspectionInput,
            day_of_week: [],
            schedule_type: value,
          });
          SetJobInputError({ ...jobInputError, [name]: false });
        } else if (value === "Monthly") {
          const {
            day_of_week: ommitday_of_week,
            month_of_year: ommitmonth_of_year,
            ...newInspectionInput
          } = jobInput;
          SetJobInput({
            ...newInspectionInput,
            day_of_month: [],
            schedule_type: value,
          });
          SetJobInputError({ ...jobInputError, [name]: false });
        } else {
          const { day_of_week: ommitday_of_week, ...newInspectionInput } =
            jobInput;
          SetJobInput({
            ...newInspectionInput,
            day_of_month: [],
            month_of_year: [],
            schedule_type: value,
          });
          SetJobInputError({ ...jobInputError, [name]: false });
        }
      } catch (error) {
        console.log("error", error);
      }
    } else {
      SetJobInput({ ...jobInput, [name]: value });
      SetJobInputError({ ...jobInputError, [name]: false });
    }
  };

  const handleSelectChange = (name, value) => {
    SetJobInput({ ...jobInput, [name]: value });
    if (jobInputError.hasOwnProperty(name) && value) {
      SetJobInputError({ ...jobInputError, [name]: false });
    }
  };

  const validateForm = () => {
    let errors = {};
    Object.entries(jobInput).forEach(([key, value]) => {
      if (!value || (Array.isArray(value) && value.length === 0)) {
        errors[key] = true;
      }
    });
    SetJobInputError({ ...jobInputError, ...errors });
    return Object.keys(errors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateForm()) {
      return false;
    }
    try {
      setIsSubmitLoading(true);
      const {
        inspect_hour: ispHr,
        inspect_minute: ispMin,
        ...newInspectionInput
      } = jobInput;
      const formData = {
        schedule_time: `${ispHr}:${ispMin}`,
        ...newInspectionInput,
      };
      const resp = await createPlannedJob(formData);
      const resp_data = resp.data.data;
      setData([resp_data, ...data]);
      setTotal(total + 1);
      toast.success(resp.data.message);
      toggleModal();
    } catch (error) {
      toast.error(error.response.data.message);
    }
    finally {
      setIsSubmitLoading(false);
    } 
  };

  return (
    <>
      <div className="card">
        <div className="card-body p-0">
          <div className="table-responsive active-projects style-1 ItemsCheckboxSec shorting">
            <div className="tbl-caption d-flex justify-content-between text-wrap align-items-center">
              <h4 className="heading mb-0 fw-bold">Job List</h4>
              <div className="d-flex">
                <div className="form-group me-5">
                  <input
                    type="search"
                    name="search"
                    className="form-control "
                    placeholder="Search"
                    autoFocus
                    value={searchQuery}
                    onChange={handleSearchChange}
                  />
                </div>
                <button
                  className="btn btn-primary btn-sm ms-1"
                  data-bs-toggle="offcanvas"
                  onClick={() => toggleModal()}
                >
                  Add Job
                </button>
              </div>
            </div>
            {isMobileScreen ? (
              <div className="row">
                {data.map((item, index) => (
                  <div className="col-sm-6" key={index}>
                    <div
                      class="card"
                      onClick={() => {
                        setSelectedJob(item);
                        toggleViewModal();
                      }}
                    >
                      <div class="card-body">
                        <p class="card-text">
                          <strong>Name: </strong>
                          <span className="text-primary cursor-pointer fw-bolder">
                            {item.name}
                          </span>
                        </p>
                        <p class="card-text">
                          <strong>Schedule: </strong>
                          <span>{item.schedule.name}</span>
                        </p>
                        <p class="card-text">
                          <strong>Work Location: </strong>
                          <span>{item.work_location.name}</span>
                        </p>
                        <p class="card-text">
                          <strong>Shift Type: </strong>
                          <span>{item.shift_type}</span>
                        </p>
                        <p class="card-text">
                          <strong>CheckList: </strong>
                          <span>{item.checklist.length}</span>
                        </p>
                        <p class="card-text">
                          <strong>Create By: </strong>
                          <span>{item.created_by?.name}</span>
                        </p>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            ) : (
              <div
                id="employee-tbl_wrapper"
                className="dataTables_wrapper no-footer"
              >
                <table
                  id="empoloyees-tblwrapper"
                  className="table ItemsCheckboxSec dataTable no-footer mb-0"
                >
                  <thead>
                    <tr>
                      <th>Name</th>
                      <th>Schedule</th>
                      <th>Work Location</th>
                      <th>Shift Type</th>
                      <th>CheckList</th>
                      <th>Create By</th>
                    </tr>
                  </thead>
                  <tbody>
                    {isLoading ? (
                      <TableLoaderEffect colLength={6} />
                    ) : (
                      data.map((item, index) => (
                        <tr key={index}>
                          <td>
                            <span
                              className="text-primary cursor-pointer fw-bolder"
                              onClick={() => {
                                setSelectedJob(item);
                                toggleViewModal();
                              }}
                            >
                              {item.name}
                            </span>
                          </td>
                          <td>
                            <span>{item.schedule.name}</span>
                          </td>
                          <td>
                            <span>{item.work_location.name}</span>
                          </td>
                          <td>
                            <span>{item.shift_type}</span>
                          </td>
                          <td>
                            <span>{item.checklist.length}</span>
                          </td>
                          <td>
                            <span>{item.created_by?.name}</span>
                          </td>
                        </tr>
                      ))
                    )}
                  </tbody>
                </table>

                {isLoading ? (
                  <div style={{ height: "4rem" }}></div>
                ) : (
                  <Pagination
                    currentPage={currentPage}
                    recordsPage={recordsPage}
                    dataLength={total}
                    handlePagination={handlePagination}
                  />
                )}
              </div>
            )}
          </div>
        </div>
      </div>

      <Modal
        show={isModalOpen}
        onHide={toggleModal}
        className="modal fade modal-md"
        placement="end"
      >
        <div className="modal-conten">
          <div className="modal-header border-bottom">
            <h5 className="modal-title" id="#gridSystemModal">
              Add Planned Job
            </h5>
            <button
              type="button"
              className="btn-close"
              onClick={toggleModal}
            ></button>
          </div>

          <div className="modal-body">
            <Form onSubmit={(e) => handleSubmit(e)}>
              <div className="row">
                <div className="col-xl-12">
                  <div className="mb-3">
                    <label className="form-check-label">
                      Name <span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      className={`form-control ${
                        jobInputError.name ? "is-invalid" : ""
                      } `}
                      id="name"
                      name="name"
                      placeholder="Enter Job Name"
                      onChange={handleChangeAdd}
                    />
                  </div>

                  <DynamicSelectInput
                    setParentInputValue={handleSelectChange}
                    endpoint_name={"search/work_location"}
                    name={"work_location_id"}
                    id={"work_location_id"}
                    label={"Work Location"}
                    placeholder={"Select Work Location"}
                    isRequired={true}
                    is_invalid={jobInputError.work_location_id}
                  />

                  <DynamicSelectInput
                    setParentInputValue={handleSelectChange}
                    endpoint_name={"search/schedule"}
                    name={"schedule_id"}
                    id={"schedule_id"}
                    label={"Schedule"}
                    placeholder={"Select Schedule"}
                    isRequired={true}
                    is_invalid={jobInputError.schedule_id}
                  />

                  <StaticSelectInput
                    setParentInputValue={handleSelectChange}
                    optionsList={[
                      { label: "General Shift", value: "General Shift" },
                      { label: "A Shift", value: "A Shift" },
                      { label: "B Shift", value: "B Shift" },
                      { label: "C Shift", value: "C Shift" },
                    ]}
                    name={"shift_type"}
                    id={"shift_type"}
                    label={"Shift Type"}
                    placeholder={"Select Shift Type"}
                    isRequired={true}
                    is_invalid={jobInputError.shift_type}
                  />

                  <DynamicSelectInput
                    setParentInputValue={handleSelectChange}
                    endpoint_name={"search/checklist"}
                    name={"checklist_id"}
                    id={"checklist_id"}
                    label={"Checklist"}
                    placeholder={"Select Checklist"}
                    isRequired={true}
                    is_invalid={jobInputError.checklist_id}
                    is_multi={true}
                  />
                </div>
              </div>
              <div className="mt-3">
                <button type="submit" className="btn btn-primary me-1">
                  Create
                </button>
                <button
                  type="button"
                  onClick={toggleModal}
                  className="btn btn-danger light ms-1"
                >
                  Cancel
                </button>
              </div>
            </Form>
          </div>
        </div>
      </Modal>

      <Modal show={isViewModalOpen} onHide={toggleViewModal}>
        <div className="modal-header">
          <h4 className="modal-title my-1" id="#gridSystemModal">
            Job Details
          </h4>
          <button
            type="button"
            className="btn-close mx-3"
            onClick={toggleViewModal}
          ></button>
        </div>
        <div className="modal-body">
          {selectedJob ? (
            <ul>
              <li className="my-3">
                <strong>Name:</strong> {selectedJob.name}
              </li>
              <li className="my-3">
                <strong>Schedule:</strong>
                {selectedJob.schedule.name}
              </li>
              <li className="my-3">
                <strong>Work Location:</strong>
                {selectedJob.work_location.name}
              </li>
              <li className="my-3">
                <strong>Shift Type:</strong>
                {selectedJob.shift_type}
              </li>
              <li className="my-3">
                <strong>Schedule Time:</strong>
                {selectedJob.schedule_time}
              </li>
              <li className="my-3">
                <strong>Created By:</strong>
                {selectedJob.created_by?.name}
              </li>
              <li className="my-3">
                <strong>Created At:</strong> {selectedJob.created_at}
              </li>
              <li className="my-3">
                <strong>CheckList:</strong>
                <div className="d-flex flex-column">
                  {selectedJob.checklist.map((item) => (
                    <span className="m-1" key={item.id}>
                      {item.name}
                    </span>
                  ))}
                </div>
              </li>
            </ul>
          ) : null}
        </div>
      </Modal>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    isMobileScreen: state.auth.isMobileScreen,
  };
};

export default connect(mapStateToProps)(PlannedJobTab);
