import axiosInstance from "./AxiosInstance";
const checkListUrl = "api/checklist";
const plannedJobScheduleUrl = "api/plannedjob_schedule";
const plannedJobUrl = "api/plannedjob";

export function getCheckListList(page, page_size, query) {
  return axiosInstance.get(
    `${checkListUrl}?page=${page}&page_size=${page_size}&query=${query}`
  );
}

export function createCheckList(data) {
  return axiosInstance.post(checkListUrl, data);
}

export function updateCheckList(data) {
  return axiosInstance.put(checkListUrl, data);
}

export function deleteCheckList(id) {
  return axiosInstance.delete(`${checkListUrl}/${id}`);
}


export function getPlannedJobScheduleList(page, page_size, query) {
  return axiosInstance.get(
    `${plannedJobScheduleUrl}?page=${page}&page_size=${page_size}&query=${query}`
  );
}

export function createPlannedJobSchedule(data) {
  return axiosInstance.post(plannedJobScheduleUrl, data);
}

export function updatePlannedJobSchedule(data) {
  return axiosInstance.put(plannedJobScheduleUrl, data);
}

export function deletePlannedJobSchedule(id) {
  return axiosInstance.delete(`${plannedJobScheduleUrl}/${id}`);
}


export function getPlannedJobList(page, page_size, query) {
  return axiosInstance.get(
    `${plannedJobUrl}?page=${page}&page_size=${page_size}&query=${query}`
  );
}

export function createPlannedJob(data) {
  return axiosInstance.post(plannedJobUrl, data);
}

export function updatePlannedJob(data) {
  return axiosInstance.put(plannedJobUrl, data);
}

export function deletePlannedJob(id) {
  return axiosInstance.delete(`${plannedJobUrl}/${id}`);
}
