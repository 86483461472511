import React from "react";
import Chart from "react-apexcharts";
import DataLoader from "../Loader";

const ShiftLogJobTypePieCard = ({ data, isLoading }) => {
 
  const options = {
    chart: {
      height: 300,
    },
    labels: ["Corrective Maintainance", "Preventive Maintainance", "Break Down Maintainance"],
    legend: {
      show: true,
      position: 'bottom'
    }
  }

  return (
    <div className="card" >
      <div className="card-header border-0 pb-0 flex-wrap">
        <h4 className="heading mb-0">Classification</h4>

      </div>
      <div className="card-body  p-0">
        {!isLoading ?
          <div id="jobtypepiechart">
            <Chart
              options={options}
              series={[data.corrective_maintainance || 0, data.preventive_maintainance || 0, data.break_down_maintainance || 0]}
              type="pie"
              height={300}
            />
          </div>
          : <DataLoader />
        }
      </div>
    </div>
  );

}

export default ShiftLogJobTypePieCard;