import axiosInstance from "./AxiosInstance";
const empUrl = "api/employee";
const emp_grpUrl = "api/employee_group";
const userUrl = "api/user";
const designationUrl = "api/designation";
const departmentUrl = "api/department";
const prodCatUrl = "api/product_category";

// user
export function getUserList(page, page_size, query) {
  return axiosInstance.get(
    `${userUrl}?page=${page}&page_size=${page_size}&query=${query}`
  );
}

export function getUser(empId) {
  return axiosInstance.get(`${userUrl}/${empId}`);
}

export function createUser(empData) {
  return axiosInstance.post(userUrl, empData);
}

export function updateUser(empData) {
  return axiosInstance.put(userUrl, empData);
}

export function deleteUser(empId) {
  return axiosInstance.delete(`${userUrl}/${empId}`);
}

export function updateUserProfilePassword(data) {
  return axiosInstance.put("api/auth/profile",data);
}

// employee

export function getEmployeeList(page, page_size, query) {
  return axiosInstance.get(
    `${empUrl}?page=${page}&page_size=${page_size}&query=${query}`
  );
}

export function getEmployee(empId) {
  return axiosInstance.get(`${empUrl}/${empId}`);
}

export function createEmployee(empData) {
  return axiosInstance.post(empUrl, empData);
}

export function updateEmployee(empData) {
  return axiosInstance.put(empUrl, empData);
}

export function deleteEmployee(empId) {
  return axiosInstance.delete(`${empUrl}/${empId}`);
}

// designation

export function getDesignationList() {
  return axiosInstance.get(`${designationUrl}`);
}

export function createDesignation(Data) {
  return axiosInstance.post(designationUrl, Data);
}

export function updateDesignation(Data) {
  return axiosInstance.put(designationUrl, Data);
}

//employee Group
export function getEmployeeGroupList() {
  return axiosInstance.get(`${emp_grpUrl}`);
}

export function createEmployeeGroup(Data) {
  return axiosInstance.post(emp_grpUrl, Data);
}

export function updateEmployeeGroup(Data) {
  return axiosInstance.put(emp_grpUrl, Data);
}

// department

export function getDepartmentList() {
  return axiosInstance.get(`${departmentUrl}`);
}

export function createDepartment(Data) {
  return axiosInstance.post(departmentUrl, Data);
}

export function updateDepartment(Data) {
  return axiosInstance.put(departmentUrl, Data);
}

// product Catgory

export function getProductCategoryList() {
  return axiosInstance.get(`${prodCatUrl}`);
}

export function createProductCategory(Data) {
  return axiosInstance.post(prodCatUrl, Data);
}

export function updateProductCategory(Data) {
  return axiosInstance.put(prodCatUrl, Data);
}

export function getAuthorized() {
  return axiosInstance.get("api/user/authorized");
}

export function getSelectOptionList(
  endPoint,
  page = 1,
  page_size = 20,
  query = ""
) {
  return axiosInstance.get(
    `api/${endPoint}?page=${page}&page_size=${page_size}&query=${query}`
  );
}
