import axiosInstance from "./AxiosInstance";
const apiUrl = "api/work_location";

export function getWorkLocationList(page, page_size, query) {
  return axiosInstance.get(
    `${apiUrl}?page=${page}&page_size=${page_size}&query=${query}`
  );
}

export function getWorkLocation(id) {
  return axiosInstance.get(`${apiUrl}/${id}`);
}

export function createWorkLocation(data) {
  return axiosInstance.post(apiUrl, data);
}

export function updateWorkLocation(data) {
  return axiosInstance.put(apiUrl, data);
}

export function deleteWorkLocation(id) {
  return axiosInstance.delete(`${apiUrl}/${id}`);
}
