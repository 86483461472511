import React from "react";

import InspctionUpdateModal from "./InspctionUpdateModal";
import { LoaderEffect } from "../../../constant/TableLoader";
const PlannedShowLogBook = ({ data, isLoading }) => {
  const StatusBadgeComponent = ({ status }) => {
    let statusbtn;
    switch (status) {
      case "Pending":
        statusbtn = (
          <span className={`badge light badge-warning badge-sm badge-rounded`}>
            Pending
          </span>
        );
        break;
      case "Completed":
        statusbtn = (
          <span className={`badge light badge-success badge-sm badge-rounded`}>
            Completed
          </span>
        );
        break;
      case "Defected":
        statusbtn = (
          <span className={`badge light badge-info badge-sm badge-rounded`}>
            Defected
          </span>
        );
        break;
      case "Overdue":
        statusbtn = (
          <span className={`badge light badge-danger badge-sm badge-rounded`}>
            Overdue
          </span>
        );
        break;
      default:
        statusbtn = (
          <span
            className={`badge light badge-secondary badge-sm badge-rounded`}
          >
            Upcoming
          </span>
        );
    }
    return statusbtn;
  };

  return isLoading ? <LoaderEffect /> :
      <div className="card-body">
        {data ? (
          <div className="d-flex justify-content-between flex-md-row flex-column">
            <div className="container mt-0">
              <ul>
                <li className="my-3">
                  <span className="fw-bold">Status:</span>{" "}
                  <StatusBadgeComponent status={data.status} />
                </li>
                <li className="my-3">
                  <span className="fw-bold">Work Location:</span>{" "}
                  {data.work_location.name}
                </li>
                <li className="my-3">
                  <span className="fw-bold">Shift:</span> {data.shift_type}
                </li>
                <li className="my-3">
                  <span className="fw-bold">Planned Job:</span>{" "}
                  {data.planned_job.name}
                </li>
                {data.entry_by && (
                  <li className="my-3">
                    <span className="fw-bold">Entry By: </span>
                    {data.entry_by.name}
                  </li>
                )}
                <li className="my-3">
                  <span className="fw-bold">Created At:</span> {data.created_at}
                </li>
                <li className="my-3">
                  <span className="fw-bold">Updated At:</span> {data.updated_at}
                </li>
              </ul>
            </div>

            <div className="container mt-0">
              <div className="d-flex align-items-center justify-content-between">
                <h5 className="mb-0">Check List</h5>
                {["Pending", "Overdue"].includes(data.status) && (
                  <InspctionUpdateModal data={data} />
                )}
              </div>
              <ul>
                {data.check_history.length > 0
                  ? data.check_history.map((item) => (
                      <li className="my-3" key={item.id}>
                        {item.is_ok ? (
                          <span className="fw-bold">
                            <i className="fa-solid fa-square-check text-success me-2"></i>{" "}
                            {item.check_list.name}
                          </span>
                        ) : (
                          <span className="fw-bold">
                            <i className="fa-solid fa-square-xmark text-danger me-2"></i>{" "}
                            {item.check_list.name}{" "}
                            <i className="fw-normal">
                              ({item.other_cross_item})
                            </i>
                          </span>
                        )}
                      </li>
                    ))
                  : data.check_list.map((item) => (
                      <li className="my-3" key={item.id}>
                        <span className="fw-bold">
                          {" "}
                          <i className="fa-solid fa-star-of-life text-primary me-2"></i>
                          {item.name}
                        </span>
                      </li>
                    ))}
              </ul>

              {data.material.length > 0 && (
                <div className="my-3">
                  <h6>Material Consumed</h6>
                  {data.material.map((item) => (
                    <span className="me-3" key={item.id}>
                      {item.name}
                    </span>
                  ))}
                </div>
              )}
              {data.remarks && (
                <div className="mt-3">
                  <strong>Remarks:</strong> {data.remarks}
                </div>
              )}
            </div>
          </div>
        ) : <h3>No Data FOund</h3>}
      </div>
};

export default PlannedShowLogBook;
