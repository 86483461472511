const TableLoaderEffect = ({ colLength }) => {
    return (
        <tr>
            <td colSpan={colLength}>
                <div style={{ height: '20rem', marginTop: '5rem' }}>
                    <div className='text-center d-flex align-items-center justify-content-center'>
                        <h2>Loading</h2>
                        <div className="spinner-grow spinner-grow-sm text-primary ms-1" role="status">
                            <span className="visually-hidden">Loading...</span>
                        </div>

                        <div className="spinner-grow spinner-grow-sm text-success ms-1" role="status">
                            <span className="visually-hidden">Loading...</span>
                        </div>

                        <div className="spinner-grow spinner-grow-sm text-warning ms-1" role="status">
                            <span className="visually-hidden">Loading...</span>
                        </div>

                        <div className="spinner-grow spinner-grow-sm text-info ms-1" role="status">
                            <span className="visually-hidden">Loading...</span>
                        </div>

                    </div>
                </div>
            </td>
        </tr>
    )
}
export default TableLoaderEffect



export const LoaderEffect = () => {
    return (
        <div style={{ height: '20rem', marginTop: '5rem' }}>
            <div className='text-center d-flex align-items-center justify-content-center'>
                <h2>Loading</h2>
                <div className="spinner-grow spinner-grow-sm text-primary ms-1" role="status">
                    <span className="visually-hidden">Loading...</span>
                </div>

                <div className="spinner-grow spinner-grow-sm text-success ms-1" role="status">
                    <span className="visually-hidden">Loading...</span>
                </div>

                <div className="spinner-grow spinner-grow-sm text-warning ms-1" role="status">
                    <span className="visually-hidden">Loading...</span>
                </div>

                <div className="spinner-grow spinner-grow-sm text-info ms-1" role="status">
                    <span className="visually-hidden">Loading...</span>
                </div>

            </div>
        </div>
    )
}
