import React from "react";
import InspectLogCountWidgetCard from "./elements/InspectLogCountWidget";
import ShiftLogOverViewCard from "./elements/ShiftLogOverview";
import InspectionLogOverViewCard from "./elements/InpectionLogOverview";

const Home = () => {
  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <div className="col-xl-12 wid-100">
            <div className="row">
              <InspectLogCountWidgetCard />
              <ShiftLogOverViewCard />
              {/* <InspectionLogOverViewCard /> */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Home;
