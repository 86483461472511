import React, { useState, useEffect } from 'react';
import { dashboardShiftLogOverview } from '../../../../services/DashboardService';
import ShiftLogEquipmentPieCard from './ShiftLogEquipmentPie';
import { Tab, Nav } from 'react-bootstrap';
import ShiftLogJobTypePieCard from './ShiftLogJobTypePie';
import ShiftLogShiftColumnCard from './ShiftLogShiftColumn';

const ShiftLogOverViewCard = () => {
    const [data, setData] = useState(null)
    const [isLoading, setIsLoading] = useState(false)

    useEffect(() => {
        setIsLoading(true)
        dashboardShiftLogOverview("week").then((response) => {
            setData(response.data.data);
        })
            .catch((error) => {
                console.log(error.response.data.message);
            });
        setIsLoading(false)
    }, [])

    const handleChangeView = (period) => {
        dashboardShiftLogOverview(period)
            .then((response) => {
                const resp_data = response.data.data;
                setData(resp_data);
            })
            .catch((error) => {
                console.log(error.response.data.message);
            });
    };

    const chartHeaderData = [
        { title: "W", type: "week" },
        { title: "M", type: "month" },
        { title: "3M", type: "3month" },
        { title: "6M", type: "6month" },
        { title: "Y", type: "year" },
    ];

    return (
        <>
            <div className="col-xl-12 col-sm-12 mb-3">
                <div className='d-flex align-items-left align-items-sm-center justify-content-between flex-column flex-md-row'>
                    <h4 className='mb-0'>Unplanned Log Overview </h4>
                    <div className='d-flex align-items-center pt-3 pt-sm-0'>
                        <h5 className='me-2 mb-0'>Filter</h5>
                        <Tab.Container defaultActiveKey={"W"}>
                            <Nav as="ul" className="nav nav-pills mix-chart-tab">
                                {chartHeaderData.map((item, index) => (
                                    <Nav.Item as="li" className="nav-item" key={index}>
                                        <Nav.Link
                                            eventKey={item.title}
                                            onClick={() => handleChangeView(item.type)}
                                        >
                                            {item.title}
                                        </Nav.Link>
                                    </Nav.Item>
                                ))}
                            </Nav>
                        </Tab.Container>
                    </div>
                </div>
            </div>

            <div className="col-xl-4 active-p">
                <ShiftLogEquipmentPieCard data={data?.equipment || []} isLoading={isLoading} />
            </div>
            <div className="col-xl-4 active-p">
                <ShiftLogShiftColumnCard data={data?.shift || []} isLoading={isLoading} />
            </div>
            <div className="col-xl-4 active-p">
                <ShiftLogJobTypePieCard data={data?.classification || {}} isLoading={isLoading} />
            </div>
        </>
    );
};

export default ShiftLogOverViewCard;