import axiosInstance from "./AxiosInstance";
export function login(personnel_number, password) {
  const postData = {
    personnel_number,
    password,
  };
  return axiosInstance.post(`api/auth/login`, postData);
}

export function logoutApi(email, password) {
  const postData = {
    email,
    password,
  };
  return axiosInstance.get(`api/auth/logout`, postData);
}

export function whoami() {
  return axiosInstance.get("api/auth/authorized");
}
