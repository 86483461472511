import React from "react";
import { useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import { LoaderEffect } from "../../../constant/TableLoader";

const UnPlannedShowLogBook = ({ data, isLoading, isMobileScreen, role }) => {
  const navigate = useNavigate();

  return isLoading ? <LoaderEffect /> :
    <>
      {isMobileScreen ? (
        <div className="card-body">
          {data ? (
            <div className="d-flex justify-content-between align-items-start">
              <ul>
                <li className="my-3">
                  <span className="fw-bold">Status: </span>
                  {data.status === "Completed" ? (
                    <span className={`badge light border-0 badge-success`}>
                      {data.status}
                    </span>
                  ) : data.status === "Ongoing" ? (
                    <span className={`badge light border-0 badge-info`}>
                      {data.status}
                    </span>
                  ) : (
                    <span className={`badge light border-0 badge-warning`}>
                      {data.status}
                    </span>
                  )}
                </li>
                <li className="my-3">
                  <span className="fw-bold">Job Type:</span> {data.job_type}
                </li>
                <li className="my-3">
                  <span className="fw-bold">Shift:</span> {data.shift_type}
                </li>
                <li className="my-3">
                  <span className="fw-bold">Classification:</span>{" "}
                  {data.classification}
                </li>
                <li className="my-3">
                  <span className="fw-bold">Occurred At:</span>{" "}
                  {data.occurred_at}
                </li>
                <li className="my-3">
                  <span className="fw-bold">Job Started At:</span>{" "}
                  {data.job_started_at}
                </li>
                <li className="my-3">
                  <span className="fw-bold">Job Ended At:</span>{" "}
                  {data.job_ended_at}
                </li>
                <li className="my-3">
                  <span className="fw-bold">Executed By:</span>{" "}
                  {data.executed_by?.name}
                </li>

                <li className="my-3">
                  <span className="fw-bold">Problem Statement:</span>{" "}
                  <table id="empoloyees-tblwrapper" className="table mt-3">
                    <thead>
                      <tr>
                        <th>Summary </th>
                        <th>Description </th>
                      </tr>
                    </thead>
                    <tbody>
                      {data.problem_statement.map((item, index) => (
                        <tr key={index}>
                          <td>
                            <span>{item.summary}</span>
                          </td>
                          <td>
                            <span>{item.description}</span>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </li>
                <li className="my-3">
                  <span className="fw-bold">Material:</span>
                  <div>
                    {data.material.map((item, index) => (
                      <ul className="me-1" key={index}>
                        <li>{item.name.toUpperCase()}</li>
                      </ul>
                    ))}
                  </div>
                </li>
                <li className="my-3">
                  <span className="fw-bold">Maintenance Activity:</span>{" "}
                  <div className="">
                    {data.maintenance_activity.map((item, index) => (
                      <ul className="me-1" key={index}>
                        <li>{item.activity.toUpperCase()}</li>
                      </ul>
                    ))}
                  </div>
                </li>
                <li className="my-3">
                  <span className="fw-bold">Comments:</span>
                  <div className="">
                    {data.comments.map((item, index) => (
                      <ul className="me-1" key={index}>
                        <li>{item.text.toUpperCase()}</li>
                      </ul>
                    ))}
                  </div>
                </li>
              </ul>
              <div>
                  {role === "Employee" && data.status === "Completed" ? (
                    ""
                  ) : (
                    <button
                      className="btn btn-primary btn-sm cursor-pointer fw-bolder"
                      onClick={() => navigate(`/log-book-edit/${data.id}`)}
                    >
                      Edit
                    </button>
                  )}
              </div>
            </div>
          ) : null}
        </div>
      ) : (
        <div className="card-body">
          {data ? (
            <div className="d-flex justify-content-between align-items-start px-5">
              <div>
                <ul>
                  <li className="my-3">
                    <span className="fw-bold">Status: </span>
                    {data.status === "Completed" ? (
                      <span className={`badge light border-0 badge-success`}>
                        {data.status}
                      </span>
                    ) : data.status === "Ongoing" ? (
                      <span className={`badge light border-0 badge-info`}>
                        {data.status}
                      </span>
                    ) : (
                      <span className={`badge light border-0 badge-warning`}>
                        {data.status}
                      </span>
                    )}
                  </li>
                  <li className="my-3">
                    <span className="fw-bold">Job Type:</span> {data.job_type}
                  </li>
                  <li className="my-3">
                    <span className="fw-bold">Shift:</span> {data.shift_type}
                  </li>
                  <li className="my-3">
                    <span className="fw-bold">Classification:</span>{" "}
                    {data.classification}
                  </li>
                  <li className="my-3">
                    <span className="fw-bold">Occurred At:</span>{" "}
                    {data.occurred_at}
                  </li>
                  <li className="my-3">
                    <span className="fw-bold">Job Started At:</span>{" "}
                    {data.job_started_at}
                  </li>
                  <li className="my-3">
                    <span className="fw-bold">Job Ended At:</span>{" "}
                    {data.job_ended_at}
                  </li>
                  <li className="my-3">
                    <span className="fw-bold">Executed By:</span>{" "}
                    {data.executed_by?.name}
                  </li>
                </ul>
              </div>

              <div>
                <ul>
                  <li className="my-3">
                    <span className="fw-bold">Problem Statement:</span>{" "}
                    <table id="empoloyees-tblwrapper" className="table mt-3">
                      <thead>
                        <tr>
                          <th>Summary </th>
                          <th>Description </th>
                        </tr>
                      </thead>
                      <tbody>
                        {data.problem_statement.map((item, index) => (
                          <tr key={index}>
                            <td>
                              <span>{item.summary}</span>
                            </td>
                            <td>
                              <span>{item.description}</span>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </li>
                  <li className="my-3">
                    <span className="fw-bold">Material:</span>
                    <div>
                      {data.material.map((item, index) => (
                        <ul className="me-1" key={index}>
                          <li>{item.name.toUpperCase()}</li>
                        </ul>
                      ))}
                    </div>
                  </li>
                  <li className="my-3">
                    <span className="fw-bold">Maintenance Activity:</span>{" "}
                    <div className="">
                      {data.maintenance_activity.map((item, index) => (
                        <ul className="me-1" key={index}>
                          <li>{item.activity.toUpperCase()}</li>
                        </ul>
                      ))}
                    </div>
                  </li>
                  <li className="my-3">
                    <span className="fw-bold">Comments:</span>
                    <div className="">
                      {data.comments.map((item, index) => (
                        <ul className="me-1" key={index}>
                          <li>{item.text.toUpperCase()}</li>
                        </ul>
                      ))}
                    </div>
                  </li>
                </ul>
              </div>

              <div>
                  {role === "Employee" && data.status === "Completed" ? (
                    ""
                  ) : (
                    <button
                      className="btn btn-primary btn-sm cursor-pointer fw-bolder"
                      onClick={() => navigate(`/log-book-edit/${data.id}`)}
                    >Edit</button>
                  )}
              </div>

            </div>
          ) : <h3>No Data Found</h3>}
        </div>
      )}
    </>
};

const mapStateToProps = (state) => {
  return {
    role: state.auth.user?.role || "Employee",
    isMobileScreen: state.auth.isMobileScreen,
  };
};

export default connect(mapStateToProps)(UnPlannedShowLogBook);
